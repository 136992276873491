import { Box, Chip } from '@mui/material';

export const SellableItemImageWrapper = ({ children, borderRadius = '12px', sold = false, hideLabel = false }) => {
  if (!sold) {
    return children;
  }

  return (
    <Box position="relative" borderRadius={borderRadius} overflow="hidden" width="100%" height="100%">
      {children}
      <Box position="absolute" top={0} right={0} bottom={0} left={0} bgcolor="#333" sx={{ opacity: 0.48 }} />
      {!hideLabel && (
        <Chip
          label="Sold item"
          size="small"
          role="presentation"
          sx={{
            position: 'absolute',
            top: 8,
            left: 8,
            zIndex: 1,
            bgcolor: '#CED4DA',
            height: 26,
            width: 80,
            span: { color: '#07020D', fontSize: 14, fontWeight: 400 },
          }}
        />
      )}
    </Box>
  );
};
