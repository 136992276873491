export const enTranslations = {
  'banner.secure_payments': 'Secure payments',
  'banner.buyer_protection': 'Buyer protection',
  'banner.delivery': 'Fast and integrated delivery',
  'banner.verified_sellers': 'Verified sellers only',

  'landing_page.sell_button': 'Sell now',
  'landing_page.view_bikes_and_parts': 'Find bike or parts',
  'landing_page.buy_bike_button': 'View all bikes',
  'landing_page.buy_parts_button': 'View all parts',
  'landing_page.section_hero.title': 'Turn Your',
  'landing_page.section_hero.sub_title': 'Bike Into Cash',
  'landing_page.section_hero.description_1': 'Create a listing for free in less than 3 minutes.',
  'landing_page.section_hero.description_2': 'Reach thousands of buyers.',
  'landing_page.schema_description':
    'Buy & sell pre-loved roadbikes, gravel bikes, and mountain bikes on Gearro, the largest online platform for second-hand bicycles. With buyer protection, fast shipping, and secure payments.',
  'Landing_page.schema_title': 'Discover pre-loved MTB, gravel or electric bikes',
  'landing_page.categories_label': 'Categories',
  'landing_page.most_recent_label': 'Recent bikes',
  'landing_page.most_popular_label': 'Recommended',
  'landing_page.most_recent_part_label': 'Bike parts',
  'landing_page.popular_brands': 'Popular brands',
  'landing_page.view_more': 'View more',
  'landing_page.sell_bike_ad.title': 'Sell your bike',
  'landing_page.sell_bike_ad.description':
    'The listing process is easy and fast, buyers respond to your submission quickly.',
  'landing_page.sell_bike_ad.button': 'Sell your bike',

  'landing_page.grid.gear_fuels_passion': 'Gear that fuels your passion',
  'landing_page.grid.gear_fuels_passion_description':
    'From peaks to trails, find cycling and winter gear for every adventure.',
  'landing_page.grid.shop_all': 'Shop all',

  'landing_page.why_gearro.why': 'Why Gearro?',

  'landing_page.why_gearro.title_1': 'Secure payments',
  'landing_page.why_gearro.description_1': 'A safe and seamless checkout with flexible payment options.',

  'landing_page.why_gearro.title_2': 'Superior shipping',
  'landing_page.why_gearro.description_2':
    'Our courier service provides insured, door- to - door pickup and delivery for any prepaid item.',

  'landing_page.why_gearro.title_3': 'Buyer protection',
  'landing_page.why_gearro.description_3':
    'Shop confidently: Full refunds and free returns if your gear doesn’t match its description.',

  'landing_page.create_listing.create_listing': 'Create a listing in {seconds} seconds',
  'landing_page.create_listing.import_from_link': 'Import from a link',

  'locales.english': 'EN',
  'locales.lithuanian': 'LT',
  'locales.polish': 'PL',

  'months.january': 'January',
  'months.february': 'February',
  'months.march': 'March',
  'months.april': 'April',
  'months.may': 'May',
  'months.june': 'June',
  'months.july': 'July',
  'months.august': 'August',
  'months.september': 'September',
  'months.october': 'October',
  'months.november': 'November',
  'months.december': 'December',

  'landing_page.section_info.label': 'How it works',

  'landing_page.section_info.sell': 'Selling',
  'landing_page.section_info.sell.question_0': 'Tell us about the bike you are selling',
  'landing_page.section_info.sell.answer_0':
    'Provide bike specifications, including size, materials, color, and other details.',
  'landing_page.section_info.sell.question_1': 'Handle inquiries',
  'landing_page.section_info.sell.answer_1':
    'Connect with potential buyers through the messaging system, answer their questions, or arrange a test ride.',
  'landing_page.section_info.sell.question_2': 'Confirm your payment',
  'landing_page.section_info.sell.answer_2': 'Meet with the buyer to receive payment or accept online payment.',

  'landing_page.section_info.buy': 'Buying',
  'landing_page.section_info.buy.question_0': 'Choose the bike you like',
  'landing_page.section_info.buy.answer_0': 'Search for bikes by size, bike type, or other bike attributes.',
  'landing_page.section_info.buy.question_1': 'Find out more about the bike',
  'landing_page.section_info.buy.answer_1': 'Meet up for a testdrive. Or if you want, get it delivered straight away.',
  'landing_page.section_info.buy.question_2': 'Confirm your payment',
  'landing_page.section_info.buy.answer_2': 'Meet with the seller to pay in cash, or arrange on online payment.',

  'landing_page.section_info.sell.title_0': '1. List for free',
  'landing_page.section_info.sell.description_0':
    'The listing process is easy and fast. Take photos of your item, describe it, and set your price.',
  'landing_page.section_info.sell.title_1': '2. Sell it, and ship it.',
  'landing_page.section_info.sell.description_1':
    'Sold! Package your bike, print your prepaid shipping label, and request a courier pick-up within 5 days.',
  'landing_page.section_info.sell.title_2': '3. Payday has arrived!',
  'landing_page.section_info.sell.description_2':
    "There are no selling fees, so you keep everything you earn. You'll receive payment once the buyer verifies that all is in order.",
  'landing_page.section_info.buy.title_0': '1. Find bicycle',
  'landing_page.section_info.buy.description_0':
    'Browse thousands of bicycles, search by brand or other specifications, and discover your ideal match.',
  'landing_page.section_info.buy.title_1': '2. Buy it',
  'landing_page.section_info.buy.description_1':
    'Ask the seller any questions, then purchase with a tap of a button. Pay securely by Stripe, a bank card, or Apple Pay.',
  'landing_page.section_info.buy.title_2': '3. Get it',
  'landing_page.section_info.buy.description_2':
    "You'll see the estimated delivery date for your order at checkout, and it will be delivered directly to your door.",

  'landing_page.section_hero.slide_1.title': 'Turn bike into cash',
  'landing_page.section_hero.slide_1.content': 'List for free in less than a minute.',
  'landing_page.section_hero.slide_1.button': 'Sell now',
  'landing_page.section_hero.slide_2.title': '#1 marketplace for pre-loved gear',
  'landing_page.section_hero.slide_2.content': 'Save an average of 30% when you buy used sports gear',
  'landing_page.section_hero.slide_2.button': 'Shop now',
  'landing_page.section_hero.slide_3.title': 'Get the feel you love for less',
  'landing_page.section_hero.slide_3.content': 'Save an average of 30% when you buy used sports gear.',
  'landing_page.section_hero.slide_3.button': 'Shop all',
  'landing_page.section_hero.slide_winter.title': 'Sell your ski & snowboard gear',
  'landing_page.section_hero.slide_winter.content': 'Sell pre-owned snow gear',
  'landing_page.section_hero.slide_winter.button': 'Sell now',
  'landing_page.section_hero.slide_winter_2.title': 'Are you ready for winter?',
  'landing_page.section_hero.slide_winter_2.content': 'Shop pre-owned snow gear',
  'landing_page.section_hero.slide_winter_2.button': 'Shop now',

  'config.labels.condition': 'Condition',
  'config.labels.shipping': 'Shipping',
  'config.labels.receiptAvailable': 'Receipt',
  'config.labels.frameColor': 'Color',
  'config.labels.frameMaterial': 'Material',
  'config.labels.wheelSize': 'Wheel size',
  'config.labels.frameSize': 'Frame size',
  'config.labels.year': 'Year',
  'config.labels.brand': 'Brand',
  'config.labels.category': 'Type',
  'config.labels.price': 'Price',
  'config.labels.keyword': 'Keyword',
  'config.labels.model': 'Model',
  'config.labels.country': 'Country',
  'config.labels.subCategory': 'Category',
  'config.labels.discipline': 'Discipline',
  'config.labels.frontTravel': 'Front travel',
  'config.labels.rearTravel': 'Rear travel',
  'config.labels.dropperTravel': 'Travel',
  'config.labels.dropperDiameter': 'Diameter',
  'config.labels.dropperActivation': 'Activation',
  'config.labels.catalogTree': 'Category',
  'config.labels.hubStandard': 'Hub standard',
  'config.labels.frontHubStandard': 'Front hub standard',
  'config.labels.rearHubStandard': 'Rear hub standard',
  'config.labels.suspensionType': 'Suspension type',
  'config.labels.suspensionMounting': 'Suspension mounting',
  'config.labels.suspensionLength': 'Suspension length',
  'config.labels.suspensionStroke': 'Suspension stroke',
  'config.labels.axleDimension': 'Axle dimension',
  'config.labels.gears': 'Gears',
  'config.labels.frontGears': 'Front gears',
  'config.labels.rearGears': 'Rear gears',
  'config.labels.placement': 'Type',
  'config.labels.activation': 'Activation',
  'config.labels.chainringMounting': 'Chainring mounting',
  'config.labels.bottomBracketMounting': 'Mounting',
  'config.labels.pedals': 'Type',
  'config.labels.frontWheelSize': 'Front wheel size',
  'config.labels.rearWheelSize': 'Rear wheel size',
  'config.labels.wheelsPlacement': 'Type',
  'config.labels.brakeType': 'Type',
  'config.labels.brakesActivation': 'Activation',
  'config.labels.hubsPlacement': 'Type',
  'config.labels.handlebarType': 'Type',
  'config.labels.gripsType': 'Type',
  'config.labels.steererType': 'Type',
  'config.labels.crankArmLength': 'Crank arm length',
  'config.labels.chainringTeeth': 'Teeth',
  'config.labels.handlebarWidth': 'Handlebar width',

  'categories.all': 'Bikes & parts',
  'categories.none': 'All categories',
  'categories.bike': 'Bikes',
  'categories.parts': 'Parts',

  'categories.city': 'City bikes',
  'categories.road': 'Road bikes',
  'categories.mountain': 'Mountain bikes',
  'categories.gravel': 'Gravel bikes',
  'categories.bmx_dirt': 'Bmx & Dirt',
  'categories.folding': 'Folding bikes',
  'categories.electric': 'Electric bikes',
  'categories.enduro': 'Enduro bikes',
  'categories.downhill': 'Downhill bikes',
  'categories.snowboard': 'Snowboard',

  'categories.other': 'Other',

  'categories.track': 'Track',
  'categories.aearo_triathlon_tt': 'Aearo/Triathlon/TT',
  'categories.single_gear': 'Single gear',
  'categories.xc_cross_country': 'XC / Cross Country',
  'categories.trail': 'Trail',
  'categories.bmx': 'Bmx',
  'categories.dirt_street': 'Dirt/Street Bikes',
  'categories.electric_city': 'City',
  'categories.electric_mountain': 'Mountain',
  'categories.electric_road': 'Road',
  'categories.electric_other': 'Other',
  'categories.cargo': 'Cargo',
  'categories.tricycle': 'Tricycle',
  'categories.tandem': 'Tandem',
  'categories.balance': 'Balance',
  'categories.unicycle': 'Unicycle',

  'categories.frame': 'Frame',
  'categories.suspension': 'Suspension',
  'categories.drivetrain': 'Drivetrain',
  'categories.brakes': 'Brakes',
  'categories.wheels': 'Wheels',
  'categories.tyres_tubes': 'Tyres',
  'sub_categories.tyres_tubes': 'Tyres',
  'categories.cockpit': 'Cockpit',
  'categories.seat': 'Seat',

  'sub_categories.frame_full_suspension': 'Full suspension',
  'sub_categories.frame_hard_tail_mtb': 'Hardtail MTB',
  'sub_categories.frame_road': 'Road',
  'sub_categories.frame_gravel': 'Gravel',
  'sub_categories.frame_full_suspension_e_bike': 'Full suspension E-Bike',
  'sub_categories.frame_hard_tail_e_bike': 'Hardtail E-Bike',
  'sub_categories.frame_city': 'City',
  'sub_categories.frame_dirt_n_street': 'Dirt & Street',

  'sub_categories.suspension_rigid_fork': 'Rigid fork',
  'sub_categories.suspension_suspension_fork': 'Suspension fork',
  'sub_categories.suspension_rear': 'Rear suspension',

  'sub_categories.drivetrain_crank': 'Crank',
  'sub_categories.drivetrain_chain': 'Chain',
  'sub_categories.drivetrain_cassette': 'Cassette',
  'sub_categories.drivetrain_shifter': 'Shifter',
  'sub_categories.drivetrain_derailleur': 'Derailleur',
  'sub_categories.drivetrain_chainring': 'Chainring',
  'sub_categories.drivetrain_bottom_bracket': 'Bottom bracket',
  'sub_categories.drivetrain_chain_guides': 'Chain guide',
  'sub_categories.drivetrain_pedals': 'Pedals',

  'sub_categories.brakes_disk': 'Disk brakes',
  'sub_categories.brakes_rim': 'Rim brakes',
  'sub_categories.brakes_rotor': 'Nrake rotor',
  'sub_categories.brakes_other': 'Other',

  'sub_categories.wheels_complete': 'Complete wheels',
  'sub_categories.wheels_hubs': 'Hubs',
  'sub_categories.wheels_rims': 'Rims',

  'sub_categories.cockpit_handlebar': 'Handlebar',
  'sub_categories.cockpit_stem': 'Stem',
  'sub_categories.cockpit_grips': 'Grips',
  'sub_categories.cockpit_headset': 'Headset',

  'sub_categories.seat_dropper_post': 'Dropper post',
  'sub_categories.seat_post': 'Seat post',
  'sub_categories.seat_saddle': 'Seat saddle',
  'sub_categories.seat_other': 'Other',

  // Snow upload
  'new_listing_page.type.winter': 'Winter gear',
  'new_listing_page.description.winter': 'Snowboards, skies, boots & accessories',

  'categories.winter': 'Winter',
  'categories.skis': 'Skis',
  'categories.accessories': 'Accessories',

  'sub_categories.skis_alpine': 'Alpine skis',
  'sub_categories.skis_touring_backcountry': 'Alpine touring / Backcountry',
  'sub_categories.skis_cross_country': 'Cross country skis',
  'sub_categories.skis_park_pipe': 'Park pipe',
  'sub_categories.skis_boots': 'Boots',
  'sub_categories.skis_bindings': 'Bindings',
  'sub_categories.skis_poles': 'Poles',
  'sub_categories.snowboard': 'Snowboard',
  'sub_categories.snowboard_boots': 'Boots',
  'sub_categories.snowboard_bindings': 'Bindings',
  'sub_categories.helmets': 'Helmets',
  'sub_categories.goggles': 'Goggles',

  'parts_listing_page.specifications.ski_length': 'Ski length (cm)',
  'parts_listing_page.specifications.ski_length_placeholder': 'Select length',
  'parts_listing_page.specifications.ski_length_required': "Please select ski's length",
  'parts_listing_page.specifications.ski_waist_width': 'Waist width',
  'parts_listing_page.specifications.ski_waist_width_placeholder': 'Select waist width',
  'parts_listing_page.specifications.with_bindings': 'Bindings included',
  'parts_listing_page.specifications.with_bindings_placeholder': 'Select',
  'parts_listing_page.specifications.gender': 'Gender',
  'parts_listing_page.specifications.gender_placeholder': 'Select gender',
  'parts_listing_page.specifications.snowboard_size': 'Size',
  'parts_listing_page.specifications.snowboard_size_placeholder': 'Select size',
  'parts_listing_page.specifications.snowboard_size_required': "Please select snowboard's size",
  'parts_listing_page.specifications.snowboard_type': 'Type',
  'parts_listing_page.specifications.snowboard_type_placeholder': 'Select type',
  'parts_listing_page.specifications.snowboard_flex_rate': 'Flex rate',
  'parts_listing_page.specifications.snowboard_flex_rate_placeholder': 'Select flex rate',
  'parts_listing_page.specifications.helmet_size': 'Size',
  'parts_listing_page.specifications.helmet_size_placeholder': 'Select size',
  'parts_listing_page.specifications.helmet_size_required': "Please select helmet's size",
  'parts_listing_page.specifications.color': 'Color',
  'parts_listing_page.specifications.color_placeholder': 'Select color',
  'parts_listing_page.specifications.goggles_frame_size': 'Frame size',
  'parts_listing_page.specifications.goggles_frame_size_placeholder': 'Select frame size',
  'parts_listing_page.specifications.goggles_frame_size_required': "Please select goggle's frame size",
  'parts_listing_page.specifications.poles_size': 'Poles size',
  'parts_listing_page.specifications.poles_size_placeholder': 'Select poles size',
  'parts_listing_page.specifications.boots_size': 'Boots size',
  'parts_listing_page.specifications.boots_size_placeholder': 'Select boots size',
  'parts_listing_page.specifications.boots_size_required': 'Please select boots size',
  'parts_listing_page.specifications.ski_boots_type': 'Boots type',
  'parts_listing_page.specifications.ski_boots_type_placeholder': 'Select boots type',
  'parts_listing_page.specifications.ski_boots_flex_rate': 'Flex rate',
  'parts_listing_page.specifications.ski_boots_flex_rate_placeholder': 'Select flex rate',
  'parts_listing_page.specifications.ski_bindings_size': 'Bindings size',
  'parts_listing_page.specifications.ski_bindings_size_placeholder': 'Select bindings size',
  'parts_listing_page.specifications.ski_bindings_size_required': 'Please select bindings size',
  'parts_listing_page.specifications.snowboard_boots_flex_rate': 'Flex rate',
  'parts_listing_page.specifications.snowboard_boots_flex_rate_placeholder': 'Please select boots flex rate',
  'parts_listing_page.specifications.snowboard_bindings_size': 'Bindings size',
  'parts_listing_page.specifications.snowboard_bindings_size_placeholder': 'Select bindings size',
  'parts_listing_page.specifications.snowboard_bindings_size_required': 'Please select bindings size',

  'gender.men': "Men's",
  'gender.women': "Women's",
  'gender.kids': 'Kids',
  'gender.unisex': 'Unisex',
  'gender.placeholder': 'Select gender',
  'with_bindings.yes': 'Yes',
  'with_bindings.no': 'No',
  'with_bindings.placeholder': 'Has bindings',
  'snowboard_type.all_mountain': 'All mountain',
  'snowboard_type.freeride': 'Freeride',
  'snowboard_type.freestyle': 'Freestyle',
  'snowboard_type.park_pipe': 'Park & Pipe',
  'snowboard_type.powder': 'Powder',
  'snowboard_type.splitboards': 'Splitboards',
  'snowboard_type.wide': 'Wide',
  'snowboard_type.placeholder': 'Select type',
  'snowboard_flex_rate.soft': 'Soft',
  'snowboard_flex_rate.medium': 'Medium',
  'snowboard_flex_rate.stiff': 'Stiff',
  'snowboard_flex_rate.very_stiff': 'Very stiff',
  'snowboard_flex_rate.placeholder': 'Select flex rate',
  'goggles_frame_size.placeholder': 'Select frame size',
  'goggles_frame_size.small': 'Small',
  'goggles_frame_size.medium': 'Medium',
  'goggles_frame_size.large': 'Large',
  'snowboard_size.placeholder': 'Select size',
  'boots_size.placeholder': 'Select size',
  'snowboard_boots_flex_rate.soft': 'Soft',
  'snowboard_boots_flex_rate.medium': 'Medium',
  'snowboard_boots_flex_rate.adjustable': 'Adjustable',
  'snowboard_boots_flex_rate.placeholder': 'Select flex rate',
  'snowboard_bindings_size.xs': 'Extra small',
  'snowboard_bindings_size.s': 'Small',
  'snowboard_bindings_size.m': 'Medium',
  'snowboard_bindings_size.l': 'Large',
  'snowboard_bindings_size.xl': 'Extra large',
  'snowboard_bindings_size.one_size': 'One size',
  'snowboard_bindings_size.other': 'Other',
  'snowboard_bindings_size.placeholder': 'Select size',
  'helmet_size.placeholder': 'Select size',
  'color.placeholder': 'Select color',
  'ski_length.placeholder': 'Select length',
  'ski_waist_width.placeholder': 'Select waist width',
  'ski_boots_type.alpine': 'Alpine',
  'ski_boots_type.alpine_touring_backcountry': 'Alpine Touring / Backcountry',
  'ski_boots_type.cross_country': 'Cross Country Skiing',
  'ski_boots_type.park_pipe': 'Park pipe',
  'ski_boots_type.placeholder': 'Select type',
  'ski_boots_flex_rate.very_soft': 'Very soft (Less than 70)',
  'ski_boots_flex_rate.soft': 'Soft (70-90)',
  'ski_boots_flex_rate.medium': 'Medium (95-115)',
  'ski_boots_flex_rate.stiff': 'Stiff (120-130)',
  'ski_boots_flex_rate.very_stiff': 'Very stiff (More than 130)',
  'ski_boots_flex_rate.placeholder': 'Select flex rate',
  'ski_bindings_size.placeholder': 'Select bindings size',
  'poles_size.adjustable': 'Adjustable',
  'poles_size.34': '34in (85cm)',
  'poles_size.36': '36in (90cm)',
  'poles_size.38': '38in (95cm)',
  'poles_size.40': '40in (100cm)',
  'poles_size.42': '42in (105cm)',
  'poles_size.44': '44in (110cm)',
  'poles_size.46': '46in (115cm)',
  'poles_size.48': '48in (120cm)',
  'poles_size.50': '50in (125cm)',
  'poles_size.52': '52in (130cm)',
  'poles_size.54': '54in (135cm)',
  'poles_size.56': '56in (140cm)',
  'poles_size.more_than_56': 'More than 56in (140cm)',
  'poles_size.other': 'Other',
  'poles_size.placeholder': 'Select size',

  'config.labels.snowboardSize': 'Size',
  'config.labels.snowboardType': 'Type',
  'config.labels.snowboardFlexRate': 'Flex rate',
  'config.labels.withBindings': 'With bindings',
  'config.labels.gender': 'Gender',
  'config.labels.bootsSize': 'Boots size',
  'config.labels.snowboardBootsFlexRate': 'Boots flex rate',
  'config.labels.snowboardBindingsSize': 'Bindings size',
  'config.labels.helmetSize': 'Helmet size',
  'config.labels.gogglesFrameSize': 'Frame size',
  'config.labels.skiLenght': 'Length',
  'config.labels.skiWaistWidth': 'Waist width',
  'config.labels.skiBootsType': 'Boots type',
  'config.labels.skiBootsFlexRate': 'Boots flex rate',
  'config.labels.skiBindingsSize': 'Bindings size',
  'config.labels.color': 'Color',
  'config.labels.polesSize': 'Poles size',

  // Snow gear end

  'year.placeholder': 'Select year',
  'frame_size.placeholder': 'Select frame size',
  'brand.placeholder': 'Select brand',
  'country.placeholder': 'Select country',
  'front_travel.placeholder': 'Select front travel',
  'rear_travel.placeholder': 'Select rear travel',

  'country.LT': 'Lithuania',
  'country.LV': 'Latvia',
  'country.EE': 'Estonia',
  'country.PL': 'Poland',
  'country.all': 'All',

  'sub_categories.road': 'Road',
  'sub_categories.track': 'Track',
  'sub_categories.aearo_triathlon_tt': 'Aearo/Triathlon/TT',
  'sub_categories.single_gear': 'Single gear',
  'sub_categories.xc_cross_country': 'XC / Cross Country',
  'sub_categories.trail': 'Trail',
  'sub_categories.enduro': 'Enduro',
  'sub_categories.downhill': 'Downhill',
  'sub_categories.bmx': 'Bmx',
  'sub_categories.dirt_street': 'Dirt/Street Bikes',
  'sub_categories.electric_city': 'City',
  'sub_categories.electric_mountain': 'Mountain',
  'sub_categories.electric_road': 'Road',
  'sub_categories.electric_other': 'Other',
  'sub_categories.cargo': 'Cargo',
  'sub_categories.tricycle': 'Tricycle',
  'sub_categories.tandem': 'Tandem',
  'sub_categories.balance': 'Balance',
  'sub_categories.unicycle': 'Unicycle',

  'shipping.no_shipping': 'Local pick up only',
  'shipping.domestic_shipping': 'Will ship within country',
  'shipping.international_shipping': 'Will ship outside country',

  'frame_material.aluminum': 'Aluminium',
  'frame_material.carbon': 'Carbon',
  'frame_material.steel': 'Steel',
  'frame_material.other': 'Other',

  'color.pink': 'Pink',
  'color.green': 'Green',
  'color.orange': 'Orange',
  'color.blue': 'Blue',
  'color.red': 'Red',
  'color.white': 'White',
  'color.yellow': 'Yellow',
  'color.black': 'Black',
  'color.grey': 'Grey',
  'color.other': 'Other',

  'wheel_size.16': '16″',
  'wheel_size.20': '20″',
  'wheel_size.28': '28″ (700c)',
  'wheel_size.26': '26”',
  'wheel_size.27.5': '27.5” (650b)',
  'wheel_size.29': '29”',
  'wheel_size.650B': '650B',
  'wheel_size.700C': '28” (700c)',
  'wheel_size.other': 'Other',

  'suspension_mounting.trunnion': 'Trunnion',
  'suspension_mounting.standard': 'Standard',

  'discipline.xc_marathon': 'XC / Marathon',
  'discipline.trail_all_mountain': 'Trail / All-Mountain',
  'discipline.enduro': 'Enduro',
  'discipline.downhill': 'Downhill',

  'hub_standard.15_110': '15 x 110mm (Boost)',
  'hub_standard.20_110': '20 x 110mm (Boost)',
  'hub_standard.9_100': '9 x 100mm',
  'hub_standard.15_100': '15 x 100mm',
  'hub_standard.20_100': '20 x 100mm',
  'hub_standard.9_135': '9 x 135mm',
  'hub_standard.10_135': '10 x 135mm',
  'hub_standard.15_142': '15 x 142mm',
  'hub_standard.15_150': '15 x 150mm',
  'hub_standard.10_170': '10 x 170mm',
  'hub_standard.12_190': '12 x 190mm',
  'hub_standard.12_197': '12 x 197mm',

  'suspension_type.air': 'Air',
  'suspension_type.coil': 'Coil',

  'axle_dimension.30': '30mm',
  'axle_dimension.29_9': '29.9mm',
  'axle_dimension.22_24': '22/24mm (DUB)',
  'axle_dimension.25': '25mm',
  'axle_dimension.24': '24mm',
  'axle_dimension.19': '19mm',
  'axle_dimension.square_taper': 'Square taper',
  'axle_dimension.octalink': 'Octalink',
  'axle_dimension.m30': 'M30',
  'axle_dimension.isis': 'ISIS',
  'axle_dimension.american': 'American',
  'axle_dimension.mid': 'Mid',

  'gears.1': '1',
  'gears.2': '2',
  'gears.3': '3',
  'gears.4': '4',
  'gears.5': '5',
  'gears.6': '6',
  'gears.7': '7',
  'gears.8': '8',
  'gears.9': '9',
  'gears.10': '10',
  'gears.11': '11',
  'gears.12': '12',
  'gears.13': '13',

  'placement.front': 'Front',
  'placement.rear': 'Rear',
  'placement.set': 'Set',

  'activation.mechanical': 'Mechanical',
  'activation.electric': 'Electric',

  'chainring_mounting.3_arm': '3-arm',
  'chainring_mounting.4_arm': '4-arm',
  'chainring_mounting.5_arm': '5-arm',

  'bottom_bracket_mounting.bsa': 'BSA',
  'bottom_bracket_mounting.ita': 'ITA',
  'bottom_bracket_mounting.pressfit': 'Pressfit',

  'pedals.flat': 'Flat',
  'pedals.clipless': 'Clipless',
  'pedals.hybrid': 'Hybrid',

  'brakes_activation.hydraulic': 'Hydraulic',
  'brakes_activation.mechanical': 'Mechanical',

  'handlebar_type.mtb': 'MTB',
  'handlebar_type.dropbar': 'Dropbar',
  'handlebar_type.bmx': 'BMX',
  'handlebar_type.city_trekking': 'City/Trekking',

  'grips_type.lock_on': 'Lock on',
  'grips_type.push_on': 'Push on',
  'grips_type.bar_tape': 'Bar tape',

  'steerer_type.1_5': '1.5”',
  'steerer_type.1_8': '1-1/8”',
  'steerer_type.threaded': 'Threaded',
  'steerer_type.tapered': 'Tapered',

  'dropper_diameter.27_2': '27.2mm',
  'dropper_diameter.28_6': '28.6mm',
  'dropper_diameter.30_9': '30.9mm',
  'dropper_diameter.31_6': '31.6mm',
  'dropper_diameter.31_8': '31.8mm',
  'dropper_diameter.34_9': '34.9mm',

  'dropper_activation.mechanical': 'Mechanical',
  'dropper_activation.electric': 'Electric',
  'dropper_activation.hydraulic': 'Hydraulic',

  'rotor_mounting.6_hole': '6-Hole',
  'rotor_mounting.centerlock': 'Centerlock',

  'rotor_placement.front': 'Front',
  'rotor_placement.rear': 'Rear',
  'rotor_placement.set': 'Set',

  'wheels_placement.front': 'Front',
  'wheels_placement.rear': 'Rear',
  'wheels_placement.set': 'Set',

  'hubs_placement.front': 'Front',
  'hubs_placement.rear': 'Rear',
  'hubs_placement.set': 'Set',

  'receipt.yes': 'Yes',
  'receipt.no': 'No',

  'condition.bad': 'Parts bike',
  'condition.partly_bad': 'Requires maintenance',
  'condition.good': 'Good',
  'condition.perfect': 'Perfect',
  'condition.brand_new': 'Brand new',

  'condition.bad.label': '✔ Unrideable bike\n✔ Inspection and service needed\n✔ Parts replacement necessary',
  'condition.partly_bad.label':
    '✔ Some scratches on the surface\n✔ Requires service adjustments\n✔ Some parts may need replacement',
  'condition.good.label':
    '✔ Some scratches on the surface\n✔ May require service adjustments\n✔ Parts replacement not necessary',
  'condition.perfect.label':
    '✔ Some light scratches\n✔ Mechanically perfect\n✔ No significant damage to any components',
  'condition.brand_new.label': '✔ The bike was never used',

  'condition.used_part': 'Used',
  'condition.good_part': 'Good',
  'condition.new_part': 'New',

  'condition.used_part.label': '✔ Previously owned, with noticeable flaws.',
  'condition.good_part.label': '✔ Lightly used, in great shape.',
  'condition.new_part.label': '✔ Mint condition, never used.',

  'frame_size.xxl': 'XXL',
  'frame_size.xl': 'XL',
  'frame_size.l_xl': 'L/XL',
  'frame_size.l': 'L',
  'frame_size.m_l': 'M/L',
  'frame_size.m': 'M',
  'frame_size.s_m': 'S/M',
  'frame_size.s': 'S',
  'frame_size.xs': 'XS',
  'frame_size.xxs': 'XSS',

  'front_travel.60': '60',
  'front_travel.70': '70',
  'front_travel.80': '80',
  'front_travel.90': '90',
  'front_travel.100': '100',
  'front_travel.110': '110',
  'front_travel.120': '120',
  'front_travel.130': '130',
  'front_travel.140': '140',
  'front_travel.150': '150',
  'front_travel.160': '160',
  'front_travel.170': '170',
  'front_travel.180': '180',
  'front_travel.190': '190',
  'front_travel.200': '200',
  'front_travel.210': '210',
  'front_travel.220': '220',
  'front_travel.230': '230',

  'rear_travel.60': '60',
  'rear_travel.70': '70',
  'rear_travel.80': '80',
  'rear_travel.90': '90',
  'rear_travel.100': '100',
  'rear_travel.110': '110',
  'rear_travel.120': '120',
  'rear_travel.130': '130',
  'rear_travel.140': '140',
  'rear_travel.150': '150',
  'rear_travel.160': '160',
  'rear_travel.170': '170',
  'rear_travel.180': '180',
  'rear_travel.190': '190',
  'rear_travel.200': '200',
  'rear_travel.210': '210',
  'rear_travel.220': '220',
  'rear_travel.230': '230',

  'top_bar.search_form.placeholder': 'Search',
  'top_bar.generic_error_message': 'Something went wrong. Please try again.',
  'top_bar.logo_icon_label': 'Go to homepage',
  'top_bar.menu_icon_label': 'Open menu',
  'top_bar.under_menu_bar_text':
    '<span style="font-size: inherit; font-weight: 600; text-decoration: underline; color: inherit">Sell your gear</span> to win a voucher of <span style="font-size: inherit; font-weight: 600; color: inherit">1000 PLN</span>',
  // Sell your gear to win a voucher of 1000 PLN

  'top_bar.log_in': 'Sign up | Log in',
  'top_bar.log_out': 'Log out',
  'top_bar.sell': 'Sell now',
  'top_bar.buy': 'Buy',
  'top_bar.inbox_label': 'Inbox',
  'top_bar.listings_label': 'Your listings',
  'top_bar.profile_settings_label': 'Profile settings',
  'top_bar.account_settings_label': 'Settings',
  'top_bar.profile': 'Profile',
  'top_bar.purchases': 'Purchases',
  'top_bar.sales': 'Sales',

  'top_bar.mobile_menu.buy': 'Buy',
  'top_bar.mobile_menu.about_page': 'About',
  'top_bar.mobile_menu.how_it_works_page': 'How it works',
  'top_bar.mobile_menu.show_profile': 'Show profile',
  'top_bar.mobile_menu.account_label': 'Account',
  'top_bar.mobile_menu.services_label': 'Services',
  'top_bar.mobile_menu.favorite_listings': 'Favorite listings',
  'top_bar.mobile_menu.explore': 'Explore',
  'top_bar.mobile_menu.all_categories': 'All categories',
  'top_bar.mobile_menu.terms_of_service': 'Terms of Service',
  'top_bar.mobile_menu.privacy_policy': 'Privacy Policy',

  'top_bar.desktop.logo_label': 'Gearro',

  'authentication_page.or': 'or',
  'authentication_page.facebook_login': 'Continue with Facebook',
  'authentication_page.google_login': 'Continue with Google',
  'authentication_page.email_login': 'Continue with email',
  'authentication_page.terms_and_conditions_accept_label':
    "By selecting Agree and continue, I agree to Gearro's {termsLink}, and the {privacyPolicyLink}",
  'authentication_page.terms_and_conditions_label': 'Terms of Service',
  'authentication_page.privacy_policy_label': 'Privacy Policy',

  'log_in.email_label': 'Email',
  'log_in.email_placeholder': 'Email',
  'log_in.email_required': 'Please enter email',
  'log_in.email_invalid': 'Provided email is invalid',
  'log_in.password_label': 'Password',
  'log_in.password_placeholder': 'Password',
  'log_in.password_required': 'Please enter password',
  'log_in.forgot_password': 'Forgot password?',
  'log_in.log_in_label': 'Log in',
  'log_in.sign_up_prompt': "Don't have an account? ",
  'log_in.sign_up_label': 'Sign up',
  'log_in.failed': 'The email and password you entered did not match our records. Please double-check and try again.',

  'sign_up.email_label': 'Email',
  'sign_up.email_placeholder': 'Email',
  'sign_up.email_required': 'Please enter email',
  'sign_up.email_invalid': 'Provided email is invalid',
  'sign_up.password_label': 'Password',
  'sign_up.password_placeholder': 'Password',
  'sign_up.password_required': 'Please enter password',
  'sign_up.password_too_short': 'The password should be at least {minLength} characters',
  'sign_up.password_too_long': 'The password should be at most {maxLength} characters',
  'sign_up.first_name_label': 'First name',
  'sign_up.first_name_placeholder': 'First name',
  'sign_up.first_name_required': 'Please enter first name',
  'sign_up.last_name_label': 'Last name',
  'sign_up.last_name_placeholder': 'Last name',
  'sign_up.last_name_required': 'Please enter last name',
  'sign_up.user_name_label': 'User name',
  'sign_up.user_name_placeholder': 'User name',
  'sign_up.user_name_required': 'Please enter user name',
  'sign_up.sign_up_label': 'Sign up',
  'sign_up.business.sign_up_label': 'Create your partner account',
  'sign_up.log_in_prompt': 'Already have an account? ',
  'sign_up.log_in_label': 'Log in',
  'sign_up.failed': 'Signup failed. Please make sure all the information you entered is correct and try again.',
  'sign_up.email_already_taken': 'Email already taken',

  'confirm_sign_up.email_label': 'Email',
  'confirm_sign_up.email_placeholder': 'Email',
  'confirm_sign_up.email_required': 'Please enter email',
  'confirm_sign_up.email_invalid': 'Provided email is invalid',
  'confirm_sign_up.first_name_label': 'First name',
  'confirm_sign_up.first_name_placeholder': 'First name',
  'confirm_sign_up.first_name_required': 'Please enter first name',
  'confirm_sign_up.last_name_label': 'Last name',
  'confirm_sign_up.last_name_placeholder': 'Last name',
  'confirm_sign_up.last_name_required': 'Please enter last name',
  'confirm_sign_up.user_name_label': 'User name',
  'confirm_sign_up.user_name_placeholder': 'User name',
  'confirm_sign_up.user_name_required': 'Please enter user name',
  'confirm_sign_up.confirm_and_continue_label': 'Agree and continue',

  'terms_of_service_page.schema_title': 'Terms of Service | {siteTitle}',

  'avatar.banned_user_label': 'Banned user',
  'avatar.deleted_user_label': 'Deleted user',

  'footer.copyright': '© Gearro',
  'footer.product': 'Product',
  'footer.buy_bike': 'Buy a bike',
  'footer.sell_bike': 'Sell a bike',
  'footer.company': 'Company',
  'footer.about_us': 'About us',
  'footer.support': 'Support',
  'footer.faq': 'FAQ',
  'footer.contact_us': 'info@gearro.com',
  'footer.business_join': 'Join as a Commercial Seller',
  'footer.legal': 'Legal',
  'footer.terms_conditions': 'Terms of Service',
  'footer.privacy_policy': 'Privacy Policy',
  'footer.instagram_link': 'Go to Instagram page',
  'footer.facebook_link': 'Go to Facebook page',
  'footer.linkedin_link': 'Go to LinkedIn page',
  'footer.content_rights': '© Gearro. All rights reserved.',

  'modal.close': 'Close modal',

  'filter_form.cancel': 'Cancel',
  'filter_form.clear': 'Clear',
  'filter_form.submit': 'Apply',

  'actions.cancel': 'Cancel',
  'actions.clear': 'Clear',
  'actions.submit': 'Apply',
  'actions.publish': 'Publish',
  'actions.continue': 'Continue',
  'actions.back': 'Back',
  'actions.add': 'Add',
  'actions.edit': 'Edit',
  'actions.pay': 'Pay',
  'actions.got_it': 'Got it',
  'actions.return_home': 'Home',

  'order_actions.accept_order': 'Accept',
  'order_actions.reject_order': 'Reject',
  'order_actions.get_shipping_label': 'Get shipping label',
  'order_actions.download_shipping_label': 'Download shipping label',
  'order_actions.request_courier': 'Request courier',
  'order_actions.track_order': 'Track order',
  'order_actions.mark_order_as_shipped': 'Mark order as shipped',
  'order_actions.mark_order_as_delivered': 'Mark order as delivered',
  'order_actions.packing_instructions': 'Packing instructions',
  'order_actions.issue_notice': 'I have an issue',
  'order_actions.mark_as_ok': 'Everything is OK',

  'search_page.title.all': 'Buy or sell MTB, road, gravel or electric bikes',
  'search_page.subtitle.all':
    'Shop mountain, road, gravel or electric bikes | Choose from brands like Cube, Trek, Canyon, Scott and many more. With Buyer Protection, Shipping & secure payments',
  'search_page.title.parts': 'Buy and Sell Bicycle Parts',
  'search_page.subtitle.parts':
    'Buy and sell parts for Road, Mountain, Gravel, Electric bicycles | Top Brands with Secure Payments & Shipping',
  'search_page.title.road': 'Buy or sell pre-loved road bikes',
  'search_page.subtitle.road':
    'Aluminium and carbon road bicycles for the best price. Choose from popular brands like Cube, Trek, Canyon, Scott and many more. | With Buyer Protection, Shipping & secure payments',
  'search_page.title.mountain': 'Buy or sell pre-loved mountain, trail, enduro,downhill, electric bikes',
  'search_page.subtitle.mountain':
    'Buy or sell used and new MTB hardtail, enduro, trail & downhill bicycles. Choose from brands like Cube, Trek, Canyon, Scott and many more. With Buyer Protection, Shipping, , Shipping & secure payments',
  'search_page.title.gravel': 'Buy or sell pre-loved gravel bikes | GEARRO',
  'search_page.subtitle.gravel':
    'Buy or sell used gravel bicycles. Choose from popular brands like Cube, Trek, Canyon, Scott and many more. | With Buyer Protection, Shipping & secure payments',
  'search_page.title.city': 'City Bikes from Trek, Giant, Specialized and more | Secure Payments & Shipping',
  'search_page.subtitle.city':
    'City bikes for the best price. Choose from popular brands like Cube, Trek, Canyon, Scott and many more. | With buyer protection, shipping & secure payments',
  'search_page.title.electric': ' Buy or sell pre-loved electric bikes',
  'search_page.subtitle.electric':
    'Electric bikes for the best price. Choose from popular brands like Cube, Trek, Canyon, Scott and many more. | With Buyer Protection, Shipping & secure payments',
  'search_page.title.bmx_dirt': 'Buy or sell pre-loved BMX & Dirt Bikes',
  'search_page.subtitle.bmx_dirt':
    'BMX and Dirt bikes for the best price. Choose from popular brands like Cube, Trek, Canyon, Scott and many more. | With buyer protection, shipping & secure payments',
  'search_page.title.winter': 'Shop All Winter Sports Equipment',
  'search_page.subtitle.winter':
    'Winter Gear for Skiing, Snowboarding, and More | Top Brands with Secure Payments & Shipping',
  'search_page.title.skis': 'Ski Equipment for Sale',
  'search_page.subtitle.skis': 'Discover Top Brands like Salomon, Atomic, and more | Secure Payments & Shipping',
  'search_page.title.snowboard': 'Snowboard Equipment for Sale',
  'search_page.subtitle.snowboard':
    'Shop Boards, Boots, and Bindings from Brands like Burton & Lib Tech | Buyer Protection Included',
  'search_page.title.accessories': 'Winter Sports Accessories for Sale',
  'search_page.subtitle.accessories': 'Find Gloves, Goggles, Helmets and More | Secure Payments & Shipping',

  'search_page.price_filter.cancel': 'Cancel',
  'search_page.price_filter.clear': 'Clear',
  'search_page.price_filter.plain_label': 'Price',
  'search_page.price_filter.label': 'Price range:',
  'search_page.price_filter.submit': 'Apply',
  'search_page.price_filter.to': 'to',
  'search_page.header.foundResults': '{count, number} {count, plural, one {result} other {results}}',
  'search_page.header.loadingResults': 'Loading search results',
  'search_page.sortBy': 'Sort by',
  'search_page.filter_plain.selected': '• {count}',
  'search_page.multiple_filter.selected': '{labelText} • {count}',
  'search_page.reset_filters': 'Reset filters',
  'search_page.no_results': 'Could not find any listings that match your search criteria.',
  'search_page.empty_state.message':
    'Unfortunately, we were unable to find any listings that match your search criteria',
  'search_page.price_filter.selected': '{minPrice} - {maxPrice}',
  'search_page.mobile_filters.label': 'Filter',
  'search_page.mobile_filters.reset': 'Reset',
  'search_page.range_filter.label.selected': '{minValue} - {maxValue}',

  'search_page.mobile.show_listings': 'Show',
  'search_page.upsell_card.title': 'Shop with confidence',
  'search_page.upsell_card.description':
    'Every Gearro purchase is backed by secure payments, trusted sellers, and easy returns.',

  'profile_page.label': 'Profile',
  'profile_page.listing.view': 'View',
  'profile_page.edit_profile': 'Edit profile',
  'profile_page.joined': 'Joined {createdAt}',
  'profile_page.bikes.seller': 'Your listings',
  'profile_page.bikes.buyer': 'Listings for sale',
  'profile_page.data_load_failed': 'Whoops, something went wrong. Please try again.',
  'profile_page.bio_label': 'About',
  'profile_page.edit_listing': 'Edit',
  'profile_page.delete_listing': 'Delete',
  'profile_page.delete_modal.title': 'Delete listing',
  'profile_page.delete_modal.subtitle': 'How did you sold your item?',
  'profile_page.delete_modal.reason.gearro': 'Found a buyer at Gearro',
  'profile_page.delete_modal.reason.outside': 'Found a buyer outside the platform',
  'profile_page.delete_modal.reason.other': 'Other',
  'profile_page.delete_modal.delete_action': 'Delete',
  'profile_page.delete_modal.cancel_action': 'Cancel',
  'profile_page.no_listings.owner_label': 'Turn Your Old Bike into Cash',

  'purchases_page.label': 'Purchases',
  'purchases_page.schema_title': 'Purchases | {siteTitle}',
  'purchases_page.placeholder': 'Bikes you’ve purchased will appear here.',
  'purchases_page.error_loading_purchases': 'Could not load purchases, please try again later.',
  'purchases_page.action_button': 'Search bikes',

  'sales_page.label': 'Sales',
  'sales_page.schema_title': 'Sales | {siteTitle}',
  'sales_page.placeholder': 'Bikes you’ve sold will appear here.',
  'sales_page.error_loading_sales': 'Could not load sales, please try again later.',
  'sales_page.action_button': 'Sell now',

  'listing_page.error_loading_listing': 'Could not load listing, please try again later.',
  'listing_page.error_creating_order': 'Could not create order, please try again later.',
  'listing_page.sold_by': '{name}',
  'listing_page.loading': 'Loading listing',
  'listing_page.order_panel': 'Sold by {name}',
  'listing_page.buy_now': 'Buy now',
  'listing_page.contact_seller': 'Message seller',
  'listing_page.add_to_favorites': 'Add to favorites',
  'listing_page.remove_from_favorites': 'Remove from favorites',
  'listing_page.description.about_bike_label': 'About bike',
  'listing_page.details.label': 'Details',
  'listing_page.image_gallery.view_photos': 'View photos ({count})',
  'listing_page.image_gallery.image_alt_text': 'Listing image {index}/{count}',
  'listing_page.image_gallery.image_thumb_alt_text': 'Listing image {index}/{count}',
  'listing_page.basic_information.label': 'Basic information',
  'listing_page.location.label': 'Location',
  'listing_page.seller_details.label': 'Seller information',
  'listing_page.offered_by.label': 'Offered by',
  'listing_page.offered_by.phone_number': 'Phone Number',

  'listing_page.shopping.label': 'Shopping on Gearro',
  'listing_page.shopping.buy_sell.title': 'Buy and sell with cyclist everywhere.',
  'listing_page.shopping.buy_sell.description':
    'Join Gearro, your one-stop destination for buying and selling high-quality gear at up to 70% off, offered by fellow cyclists.',
  'listing_page.shopping.protection.title': 'Shop safely with our buyer protection.',
  'listing_page.shopping.protection.description':
    "If your order doesn't match the ad description significantly, get in touch within 48 hours. Inform us about the issue, and we'll review your request. If it's valid, we'll swiftly refund your purchase.",
  'listing_page.shopping.shipping.title': 'Quick shipping and tracking.',
  'listing_page.shopping.shipping.description':
    'Most orders ship via DPD Express (1-3 business days after the seller ships). Sellers get prepaid labels, and you receive tracking until delivery.',
  'listing_page.shopping.save.title': 'Save money. Save planet.',
  'listing_page.shopping.save.description':
    'Saving on quality used gear keeps more equipment on the field and helps the environment.',

  'listing_page.buyer_protection_info.label': 'Gearro Buyer Protection',
  'listing_page.buyer_protection_info.description': 'Buy confidently with Gearro Buyer Protection program.',
  'listing_page.buyer_protection_info.proposition.shipping': 'Fast & insured shipping',
  'listing_page.buyer_protection_info.proposition.secure_payments': 'Secure payments',
  'listing_page.buyer_protection_info.proposition.verified_sellers': 'Verified sellers',
  'listing_page.buyer_protection_info.proposition.returns': 'Free returns',
  'listing_page.similar_items_label': 'Similar items',
  'listing_page.view_more': 'View more',
  'listing_page.more_details.label': 'More details',
  'listing_page.pay_at_gearro':
    'To protect your payment, never communicate or transfer money outside of the Gearro website.',

  'listing_order_page.schema_title': 'Buy {title} | {siteTitle}',
  'listing_order_page.summary': 'Summary',
  'listing_order_page.shipping': 'Shipping',
  'listing_order_page.payment': 'Payment',
  'listing_order_page.listing_summary.info_section_message':
    "Buy confidently with Gearro's Buyer Protection program. You're covered for a full refund if your item doesn't match the description.",
  'listing_order_page.listing_summary.your_purchase': 'Your purchase',
  'listing_order_page.listing_summary.size': 'Size',
  'listing_order_page.listing_summary.price_details': 'Price details',
  'listing_order_page.listing_summary.bike_price': 'Listing price',
  'listing_order_page.listing_summary.purchase_protection': 'Buyer protection fee',
  'listing_order_page.listing_summary.shipping': 'Shipping',
  'listing_order_page.listing_summary.total': 'Total ({currency})',
  'listing_order_page.shipping.delivery_provider': 'DPD Express Shipping',
  'listing_order_page.shipping.delivery_estimate': 'Est delivery: {from} - {to}',
  'listing_order_page.shipping.delivery_info': 'Delivery information',
  'listing_order_page.shipping.add_delivery_info': 'Add delivery information',
  'listing_order_page.shipping.save_address': 'Save address',
  'listing_order_page.shipping.save_address_error': 'Failed to save address, please try again later.',
  'listing_order_page.shipping.missing_address_error': 'Your delivery information is required to complete the order.',
  'listing_order_page.shipping.delivery_info_disclaimer':
    'Your contact details may be shared with the shipping provider to complete this shipment.',
  'listing_order_page.delivery_info.first_name': 'First name',
  'listing_order_page.delivery_info.first_name_placeholder': 'First name',
  'listing_order_page.delivery_info.first_name_required': 'Please enter first name',
  'listing_order_page.delivery_info.last_name': 'Last name',
  'listing_order_page.delivery_info.last_name_placeholder': 'Last name',
  'listing_order_page.delivery_info.last_name_required': 'Please enter last name',
  'listing_order_page.delivery_info.country': 'Country',
  'listing_order_page.delivery_info.country_placeholder': 'Country',
  'listing_order_page.delivery_info.address_line_1': 'Address line 1',
  'listing_order_page.delivery_info.address_line_1_placeholder': 'Address Line 1',
  'listing_order_page.delivery_info.address_line_1_required': 'Please enter address line 1',
  'listing_order_page.delivery_info.address_line_2': 'Address line 2',
  'listing_order_page.delivery_info.address_line_2_placeholder': 'Address Line 2',
  'listing_order_page.delivery_info.postal_code': 'Postal code',
  'listing_order_page.delivery_info.postal_code_placeholder': 'Postal code',
  'listing_order_page.delivery_info.postal_code_required': 'Please enter postal code',
  'listing_order_page.delivery_info.city': 'City',
  'listing_order_page.delivery_info.city_placeholder': 'City',
  'listing_order_page.delivery_info.city_required': 'Please enter city',
  'listing_order_page.delivery_info.phone_number': 'Phone number',
  'listing_order_page.delivery_info.phone_number_placeholder': 'Phone number',
  'listing_order_page.delivery_info.phone_number_required': 'Please enter phone number',
  'listing_order_page.payment.or_card': 'Or pay with card',
  'listing_order_page.payment.mobile_pay_inactive': 'Mobile pay is not available',

  'listing_order_processing_page.schema_title': 'Processing order | {siteTitle}',
  'listing_order_processing_page.title': 'Purchases',
  'listing_order_processing_page.processing': 'Processing order, please wait...',
  'listing_order_processing_page.error':
    'Order processing failed, please check your order status in your profile or contact support.',

  'listing_order_details_page.schema_title': 'Order details | {siteTitle}',
  'listing_order_details_page.title': 'Purchases',
  'listing_order_details_page.order_placed': 'Order placed',
  'listing_order_details_page.whats_next': "What's next?",
  'listing_order_details_page.stage_1.title': 'Order request',
  'listing_order_details_page.stage_1.description':
    "Waiting for seller's approval. If confirmation is not received within 24 hours, the transaction will auto-cancel.",
  'listing_order_details_page.stage_2.title': 'Packing & shipping',
  'listing_order_details_page.stage_2.description':
    "The seller has a 5-day window to pack and ship your order. You'll receive shipment tracking once it's on the way.",
  'listing_order_details_page.stage_3.title': 'Order confirmation',
  'listing_order_details_page.stage_3.description':
    'You have a 48-hour window after the item is delivered to review and confirm that it matches its description.',

  'listing_order_info_page.schema_title': 'Order info | {siteTitle}',

  'listing_order_status_page.schema_title': 'Order status | {siteTitle}',
  'listing_order_status_page.title': 'Order status',
  'listing_order_status_page.message_seller': 'Message seller',
  'listing_order_status_page.message_buyer': 'Message buyer',
  'listing_order_status_page.order_status': 'Order status',
  'listing_order_status_page.delivery_address': 'Delivery address',
  'listing_order_status_page.offered_by': 'Offered by',
  'listing_order_status_page.bought_by': 'Bought by',
  'listing_order_status_page.payment_info': 'Payment information',
  'listing_order_status_page.price_details': 'Price details',
  'listing_order_status_page.bike_price': 'Listing price',
  'listing_order_status_page.purchase_protection': 'Buyer protection fee',
  'listing_order_status_page.shipping': 'Shipping',
  'listing_order_status_page.total': 'Total ({currency})',
  'listing_order_status_page.return_policy': 'Return policy',
  'listing_order_status_page.return_policy_info': `
  If your order doesn't match the ad description significantly, get in touch within 48 hours. Inform us about the issue, and we'll review your request. If it's valid, we'll swiftly refund your purchase.
  `,
  'listing_order_status_page.get_help': 'Get help',
  'listing_order_status_page.get_help_info': 'If you need any any help, please contact us at info@gearro.com',
  'listing_order_status_page.reject_order_modal.title': 'Reject order request?',
  'listing_order_status_page.reject_order_modal.description': "You can't undo this action.",
  'listing_order_status_page.reject_order_modal.reject_button': 'Yes, reject',
  'listing_order_status_page.reject_order_modal.cancel_button': 'Cancel',
  'listing_order_status_page.error': 'Failed to load order status, please check URL or try again later.',
  'listing_order_status_page.mark_as_ok_modal.title': 'Confirm everything is OK and complete order?',
  'listing_order_status_page.mark_as_ok_modal.description':
    "After you confirm everything's OK, your payment will be released to the seller and you will no longer be able to report any issues.",
  'listing_order_status_page.mark_as_ok_modal.confirm_button': 'Yes, everything is OK',
  'listing_order_status_page.mark_as_ok_modal.cancel_button': 'Cancel',

  'listing_order_seller_address_page.schema_title': 'Get shipping label | {siteTitle}',
  'listing_order_seller_address_page.title': 'Get shipping label',
  'listing_order_seller_address_page.return_page': 'Order status',
  'listing_order_seller_address_page.shipping.delivery_provider': 'DPD Express Shipping',
  'listing_order_seller_address_page.shipping.provider_info': 'DPD door-to-door service',
  'listing_order_seller_address_page.shipping.pick_up_info': 'Pick-up information',
  'listing_order_seller_address_page.shipping.add_delivery_info': 'Add pick-up information',
  'listing_order_seller_address_page.shipping.save_address_error': 'Failed to save address, please try again later.',
  'listing_order_seller_address_page.shipping.get_label_btn': 'Get shipping label',

  'listing_order_courier_request_page.schema_title': 'Request a courier pick-up | {siteTitle}',
  'listing_order_courier_request_page.title': 'Request a courier pick-up',
  'listing_order_courier_request_page.return_page': 'Order status',
  'listing_order_courier_request_page.form_title': 'Schedule a pick-up',
  'listing_order_courier_request_page.form_subtitle': 'Request a courier pick-up from your address.',
  'listing_order_courier_request_page.form_date_label': 'Pick-up date',
  'listing_order_courier_request_page.form_date_placeholder': 'Select a pick-up date',
  'listing_order_courier_request_page.form_date_required': 'Please select a pick-up date',
  'listing_order_courier_request_page.form_time_label': 'Pick-up time',
  'listing_order_courier_request_page.form_time_placeholder': 'Select a pick-up time',
  'listing_order_courier_request_page.form_time_required': 'Please select a pick-up time',
  'listing_order_courier_request_page.request_pick_up_btn': 'Request pick-up',

  'orders_page.schema_title': 'Orders | {siteTitle}',
  'orders_page.purchases': 'Purchases',
  'orders_page.sales': 'Sales',

  'listing_stage_info_page.schema_title': 'Listing info | {siteTitle}',
  'listing_stage_info_page.uploaded': 'Uploaded',
  'listing_stage_info_page.whats_next': "What's next?",
  'listing_stage_info_page.stage_1.title': 'Wait for order request',
  'listing_stage_info_page.stage_1.description':
    'After receiving a request from a buyer, you will have a 24-hour window to accept it.',
  'listing_stage_info_page.stage_2.title': 'Pack & ship',
  'listing_stage_info_page.stage_2.description': `After accepting the buyer's request, you'll have 5 days to pack and ship the order. Shipment instructions will follow.`,
  'listing_stage_info_page.stage_3.title': 'Payout',
  'listing_stage_info_page.stage_3.description':
    'The payment will be automatically processed 48 hours after the item is successfully delivered to the buyer.',

  'new_listing_page.page_title': 'What will you sell?',
  'new_listing_page.type.bike': 'Bicycle',
  'new_listing_page.description.bike': 'Complete bicycle, any condition.',
  'new_listing_page.type.part': 'Bike components',
  'new_listing_page.description.part': 'Frame, fork, wheels or other parts.',

  'edit_listing_page.location.label': 'Location',
  'edit_listing_page.details.label': 'Details',
  'edit_listing_page.categories.label': 'Type',
  'edit_listing_page.general_details.label': 'Photos & details',
  'edit_listing_page.show_listing_failed': 'Fetching listing data failed',
  'edit_listing_page.update_failed': 'Failed to update listing. Please try again.',
  'edit_listing_page.upload_failed': 'Publishing of listing failed',
  'edit_listing_page.max_length': 'Must be {maxLength} characters or less',

  'edit_listing_page.location.tab_title': 'Sell your new or second hand bike',
  'edit_listing_page.location.address': 'Where is your bike located?',
  'edit_listing_page.location.address_hint': 'This will not bee seen to other members.',
  'edit_listing_page.location.address_not_recognized':
    "We didn't recognize this location. Please try another location.",
  'edit_listing_page.location.address_placeholder': 'Enter address',
  'edit_listing_page.location.address_required': 'You need to provide a location',
  'edit_listing_page.location.shipping_label': 'Will you ship a bike?',
  'edit_listing_page.location.shipping_required': 'Please choose shipping method',

  'edit_listing_page.categories.tab_title': 'Which of the best describes your bike?',
  'edit_listing_page.categories.type_select': 'Select the type of your bike',
  'edit_listing_page.categories.sub_category_required': "Please choose bike's sub-category",
  'edit_listing_page.categories.category_required': "Please choose bike's category",

  'edit_listing_page.details.tab_title': 'General information about your bike',
  'edit_listing_page.details.frame_details': 'Frame details',
  'edit_listing_page.details.brand': 'Brand',
  'edit_listing_page.details.brand_placeholder': "Choose bike's brand",
  'edit_listing_page.details.brand_required': 'Please select brand',
  'edit_listing_page.details.model': 'Model',
  'edit_listing_page.details.model_placeholder': "Enter model of bike's frame",
  'edit_listing_page.details.model_required': "Please enter model of bike's frame",
  'edit_listing_page.details.year': 'Year',
  'edit_listing_page.details.year_placeholder': 'Select a year',
  'edit_listing_page.details.year_required': 'Please select a year',
  'edit_listing_page.details.frame_size': 'Frame size',
  'edit_listing_page.details.frame_size_placeholder': 'Select frame size',
  'edit_listing_page.details.frame_size_required': 'Please select frame size',
  'edit_listing_page.details.frame_material': 'Material',
  'edit_listing_page.details.frame_material_required': 'Please select material',
  'edit_listing_page.details.frame_color': 'Colour',
  'edit_listing_page.details.frame_color_required': 'Please select colour',
  'edit_listing_page.details.wheel_size': 'Wheel size',
  'edit_listing_page.details.wheel_size_info':
    "Wheel size is the diameter of the bike's wheel, usually noted on the tire.",
  'edit_listing_page.details.wheel_size_placeholder': 'Select wheel size',
  'edit_listing_page.details.wheel_size_required': 'Please select wheel size',
  'edit_listing_page.details.component_details': 'Component details',
  'edit_listing_page.details.component_details_placeholder':
    'What components - groupset, wheels, fork, shocks, seatpost, etc. - are on the bike? E.g/ Shimano Dura-Ace groupset, Bontrager Aeolus Pro 3v TLR wheels...',
  'edit_listing_page.details.component_details_required': 'Please enter component details',
  'edit_listing_page.details.frame_size_table_title': 'The right frame size',
  'edit_listing_page.details.frame_size_table_info':
    "Your bike's frame size is typically located on the seat tube of the frame. If you're unable to locate it, consult the table below.",
  'edit_listing_page.details.frame_size_table_header_1': 'Frame size',
  'edit_listing_page.details.frame_size_table_header_2': 'Frame in cm',
  'edit_listing_page.details.wheel_size.hint':
    'Wheel size is the diameter of the bike’s wheel, usually noted on the tire.',

  'edit_listing_page.general_details.tab_title': 'Add some photos of your bike',
  'edit_listing_page.general_details.image_upload_failed': 'Failed to upload image',
  'edit_listing_page.general_details.image_upload_over_limit': 'The maximum allowed file size is 20 MB',
  'edit_listing_page.general_details.image_required': 'Please upload at least 3 photos',
  'edit_listing_page.general_details.parts.image_required': 'Please upload at least 3 pictures',
  'edit_listing_page.general_details.image_upload.info': 'Choose at least 3 photos',
  'edit_listing_page.general_details.image_upload.from_device': 'Upload from your device',
  'edit_listing_page.general_details.image_upload.add_more': 'Add more',
  'edit_listing_page.general_details.image_upload.main_image': 'Main image',
  'edit_listing_page.general_details.title': 'Title',
  'edit_listing_page.general_details.title_placeholder': 'e.g. Trek slash 9.7',
  'edit_listing_page.general_details.title_required': 'Please enter title',
  'edit_listing_page.general_details.condition': 'Condition',
  'edit_listing_page.general_details.condition_required': "Please pick bike's condition",
  'edit_listing_page.general_details.receipt': 'Does the bike have original receipt?',
  'edit_listing_page.general_details.receipt_required': 'Please provide receipt availability',
  'edit_listing_page.general_details.price': 'Price',
  'edit_listing_page.general_details.placeholder': '€0.00',
  'edit_listing_page.general_details.price_required': 'Please specify price',
  'edit_listing_page.general_details.price_too_low': 'Price should be at least {minPrice}.',
  'edit_listing_page.general_details.price_too_high': "Price shouldn't exceed {maxPrice}.",
  'edit_listing_page.general_details.saved_image_alt': 'Saved listing image',

  'edit_listing_page.payment_info.tab_title': 'Payment information',
  'edit_listing_page.payment_info.label': 'Payment information',
  'edit_listing_page.payment_info.info_section_title': 'Complete your profile to enable payouts for your bike',
  'edit_listing_page.payment_info.info_section_message':
    "To process your bike payout, please enter your details below. You'll only need to do this once.",
  'edit_listing_page.payment_info.first_name': 'First name',
  'edit_listing_page.payment_info.first_name_placeholder': 'First name',
  'edit_listing_page.payment_info.first_name_required': 'Please enter first name',
  'edit_listing_page.payment_info.last_name': 'Last name',
  'edit_listing_page.payment_info.last_name_placeholder': 'Last name',
  'edit_listing_page.payment_info.last_name_required': 'Please enter last name',
  'edit_listing_page.payment_info.date_of_birth': 'Date of birth',
  'edit_listing_page.payment_info.date_of_birth_placeholder': 'Date of birth',
  'edit_listing_page.payment_info.date_of_birth_required': 'Please enter a valid date of birth',
  'edit_listing_page.payment_info.address_line_1': 'Address line 1',
  'edit_listing_page.payment_info.address_line_1_placeholder': 'Address Line 1',
  'edit_listing_page.payment_info.address_line_1_required': 'Please enter address line 1',
  'edit_listing_page.payment_info.address_line_2': 'Address line 2',
  'edit_listing_page.payment_info.address_line_2_placeholder': 'Address Line 2',
  'edit_listing_page.payment_info.postal_code': 'Postal code',
  'edit_listing_page.payment_info.postal_code_placeholder': 'Postal code',
  'edit_listing_page.payment_info.postal_code_required': 'Please enter postal code',
  'edit_listing_page.payment_info.city': 'City',
  'edit_listing_page.payment_info.city_placeholder': 'City',
  'edit_listing_page.payment_info.city_required': 'Please enter city',

  'FieldBirthdayInput.birthdayDatePlaceholder': 'DD',
  'FieldBirthdayInput.birthdayMonthPlaceholder': 'MM',
  'FieldBirthdayInput.birthdayYearPlaceholder': 'YYYY',

  'edit_listing_page.payment_info.terms_and_conditions_required': 'Please accept terms and conditions',
  'edit_listing_page.payment_info.payment_provider_error':
    'Something went wrong with payment provider. Please try again.',
  'edit_listing_page.payment_info.generic_error': 'Something went wrong. Please try again.',

  'parts_listing_page.details.label': 'Details',
  'parts_listing_page.categories.label': 'Type',
  'parts_listing_page.general_details.label': 'Photos & details',
  'parts_listing_page.specifications.label': 'Specifications',
  'parts_listing_page.payment_information.label': 'Payment information',

  'parts_listing_page.categories.tab_title': 'What will you sell?',
  'parts_listing_page.details.tab_title': 'General information about your gear',
  'parts_listing_page.specification.tab_title': 'Share gear specifications',

  'parts_listing_page.specification.panel_description': 'Increase your chances of a sale by providing extra details.',

  'parts_listing_page.details.sub_category': 'Subcategory',
  'parts_listing_page.details.sub_category_required': 'Please select sub-category',
  'parts_listing_page.details.sub_category_placeholder': 'Choose sub-category',

  'parts_listing_page.details.brand': 'Brand',
  'parts_listing_page.details.brand_placeholder': 'Choose component brand',
  'parts_listing_page.details.brand_required': 'Please select brand',
  'parts_listing_page.details.model': 'Model',
  'parts_listing_page.details.model_placeholder': 'Enter component model',
  'parts_listing_page.details.model_required': 'Please enter component model',
  'parts_listing_page.details.year_placeholder': 'Select year',

  'parts_listing_page.specification.more_details': 'More details',
  'parts_listing_page.specifications.frame_size': 'Size',
  'parts_listing_page.specifications.frame_size_placeholder': 'Select size',
  'parts_listing_page.specifications.frame_size_required': 'Please choose size',
  'parts_listing_page.specifications.frame_material': 'Material',
  'parts_listing_page.specifications.frame_material_placeholder': 'Select material',
  'parts_listing_page.specifications.frame_material_required': 'Please select material',
  'parts_listing_page.specifications.wheel_size': 'Wheel size',
  'parts_listing_page.specifications.wheel_size_placeholder': 'Select wheel size',
  'parts_listing_page.specifications.wheel_size_required': 'Please select wheel size',
  'parts_listing_page.specifications.front_travel': 'Front travel',
  'parts_listing_page.specifications.front_travel_placeholder': 'Enter front travel',
  'parts_listing_page.specifications.front_travel_required': 'Please enter front travel',
  'parts_listing_page.specifications.rear_travel': 'Rear travel',
  'parts_listing_page.specifications.rear_travel_placeholder': 'Enter reat travel',
  'parts_listing_page.specifications.rear_travel_required': 'Please enter rear travel',
  'parts_listing_page.specifications.discipline': 'Disclipline',
  'parts_listing_page.specifications.discipline_placeholder': 'Select discipline',
  'parts_listing_page.specifications.hub_standard': 'Hub standard',
  'parts_listing_page.specifications.hub_standard_placeholder': 'Select hub standard',
  'parts_listing_page.specifications.suspension_type': 'Type',
  'parts_listing_page.specifications.suspension_mounting': 'Mounting',
  'parts_listing_page.specifications.suspension_length': 'Length',
  'parts_listing_page.specifications.suspension_length_placeholder': 'Enter length',
  'parts_listing_page.specifications.suspension_stroke': 'Stroke',
  'parts_listing_page.specifications.suspension_stroke_placeholder': 'Enter stroke length',
  'parts_listing_page.specifications.axle_dimension': 'Axle dimension',
  'parts_listing_page.specifications.axle_dimension_placeholder': 'Select axle dimension',
  'parts_listing_page.specifications.crank_arm_length': 'Crank arm length',
  'parts_listing_page.specifications.crank_arm_length_placeholder': 'Enter crank arm length',
  'parts_listing_page.specifications.gears': 'Gears',
  'parts_listing_page.specifications.gears_placeholder': 'Select gears',
  'parts_listing_page.specifications.placement': 'Type',
  'parts_listing_page.specifications.activation': 'Activation',
  'parts_listing_page.specifications.front_gears': 'Front gears',
  'parts_listing_page.specifications.front_gears_placeholder': 'Select front gears',
  'parts_listing_page.specifications.rear_gears': 'Rear gears',
  'parts_listing_page.specifications.rear_gears_placeholder': 'Select rear gears',
  'parts_listing_page.specifications.chainring_mounting': 'Mounting',
  'parts_listing_page.specifications.chainring_mounting_placeholder': 'Select mounting',
  'parts_listing_page.specifications.chainring_teeth': 'Teeth',
  'parts_listing_page.specifications.chainring_teeth_placeholder': 'Enter teeth number',
  'parts_listing_page.specifications.bottom_bracket_mounting': 'Mounting',
  'parts_listing_page.specifications.bottom_bracket_mounting_placeholder': 'Select mounting',
  'parts_listing_page.specifications.bottom_bracket_width': 'Width',
  'parts_listing_page.specifications.bottom_bracket_width_placeholder': 'Enter width',
  'parts_listing_page.specifications.pedals': 'Type',
  'parts_listing_page.specifications.pedals_placeholder': 'Select type',
  'parts_listing_page.specifications.brake_type': 'Type',
  'parts_listing_page.specifications.brakes_activation': 'Activation',
  'parts_listing_page.specifications.rotor_mounting': 'Mounting',
  'parts_listing_page.specifications.rotor_placement': 'Type',
  'parts_listing_page.specifications.front_disk_diameter': 'Front disk diameter',
  'parts_listing_page.specifications.front_disk_diameter_placeholder': 'Enter front disk diameter',
  'parts_listing_page.specifications.rear_disk_diameter': 'Rear disk diameter',
  'parts_listing_page.specifications.rear_disk_diameter_placeholder': 'Enter rear disk diameter',
  'parts_listing_page.specifications.wheels_placement': 'Type',
  'parts_listing_page.specifications.front_wheel_size': 'Front wheel size',
  'parts_listing_page.specifications.front_wheel_size_placeholder': 'Select front wheel size',
  'parts_listing_page.specifications.rear_wheel_size': 'Rear wheel size',
  'parts_listing_page.specifications.rear_wheel_size_placeholder': 'Select rear wheel size',
  'parts_listing_page.specifications.hubs_placement': 'Type',
  'parts_listing_page.specifications.front_hub_standard': 'Front dimension',
  'parts_listing_page.specifications.front_hub_standard_placeholder': 'Select front hub dimension',
  'parts_listing_page.specifications.rear_hub_standard': 'Rear dimension',
  'parts_listing_page.specifications.rear_hub_standard_placeholder': 'Select rear hub dimension',
  'parts_listing_page.specifications.inner_rim_width': 'Inner rim width',
  'parts_listing_page.specifications.inner_rim_width_placeholder': 'Enter inner rim width',
  'parts_listing_page.specifications.handlebar_type': 'Type',
  'parts_listing_page.specifications.handlebar_type_placeholder': 'Select handlebar type',
  'parts_listing_page.specifications.handlebar_width': 'Width',
  'parts_listing_page.specifications.handlebar_width_placeholder': 'Enter handlebar width',
  'parts_listing_page.specifications.stem_length': 'Length',
  'parts_listing_page.specifications.stem_length_placeholder': 'Enter stem length',
  'parts_listing_page.specifications.grips_type': 'Type',
  'parts_listing_page.specifications.grips_type_placeholder': 'Select type',
  'parts_listing_page.specifications.steerer_type': 'Steerer type',
  'parts_listing_page.specifications.steerer_type_placeholder': 'Select steerer type',
  'parts_listing_page.specifications.dropper_travel': 'Travel',
  'parts_listing_page.specifications.dropper_travel_placeholder': 'Enter travel',
  'parts_listing_page.specifications.dropper_diameter': 'Diameter',
  'parts_listing_page.specifications.dropper_diameter_placeholder': 'Select diameter',
  'parts_listing_page.specifications.dropper_activation': 'Activation',
  'parts_listing_page.specifications.dropper_activation_placeholder': 'Select activation',
  'parts_listing_page.specifications.seat_length': 'Length',
  'parts_listing_page.specifications.seat_length_placeholder': 'Enter seat length',
  'parts_listing_page.specifications.brake_type_placeholder': 'Select type',

  'parts_listing_page.general_details.tab_title': 'Add some photos of your gear',
  'parts_listing_page.general_details.description.label': 'Description',
  'parts_listing_page.general_details.description.placeholder':
    'Other information about selling item, include description of flaws, packaging, receipt and other important specifications.',
  'parts_listing_page.general_details.description.required': 'Please provide gear description',
  'parts_listing_page.location.label': 'Location',
  'parts_listing_page.general_details.shipping.label': 'Shipping',
  'parts_listing_page.general_details.shipping.required': 'Please select shipping option',
  'parts_listing_page.general_details.image_upload.info': 'Choose at least 3 photos',

  'your_listings_page.edit_listing': 'Edit listing',
  'your_listings_page.delete_listing': 'Delete listing',
  'your_listings_page.delete_draft': 'Delete draft',
  'your_listings_page.title': 'Bikes for sale',
  'your_listings_page.schema_title': 'Your listings',
  'your_listings_page.delete_modal.title': 'Are you sure you want to delete bike?',
  'your_listings_page.delete_modal.delete_action': 'Delete',
  'your_listings_page.delete_modal.cancel_action': 'Cancel',

  'inbox_page.label': 'Inbox',
  'inbox_page.empty_state.message': "You don't have any messages",
  'inbox_page.message_input.placeholder': 'Send a message',

  'about_page.title': 'About us',
  'about_page.mission_statement':
    "We're on a mission to make cycling accessible and affordable for everyone, regardless of their age, gender, physical ability, or socioeconomic status. We're passionate about promoting sustainable transportation and helping people adopt healthy lifestyles. That's why our platform connects buyers with sellers from all over the world, providing a wide range of high-quality used bikes at affordable prices.",
  'about_page.sustainability_title': 'Sustainability',
  'about_page.sustainability_statement':
    'Cycling and buying a used bike are sustainable choices that benefit both individuals and the environment. Cycling reduces carbon emissions and air pollution, while buying a used bike extends its lifespan and reduces waste. Additionally, cycling promotes a healthier lifestyle, reducing the burden on the healthcare system. These sustainable choices contribute to a healthier and more sustainable future for all.',
  'about_page.our_work_title': 'Our work',
  'about_page.our_work_statement':
    'We are here since the very first downhill race in Lithuania in 2008. Our deep passion for cycling and the sense of community it brings has driven us ever since. We have poured our hearts into fostering a welcoming environment where everyone can access the joys of biking. We have created films, organized events, taught others what we have learned the hard way, and we strive to spread our passion for cycling far and wide. We firmly believe that the love of riding should be shared and are dedicated to making that a reality.',
  'about_page.our_team_title': 'Our team',
  'about_page.our_team_statement':
    "We're a passionate team based in Vilnius, Lithuania, dedicated to solving biking problems. We strive to improve the cycling experience and promote sustainable transportation solutions. Let's work together to create a brighter future for all.",

  'profile_settings.title': 'Profile settings',
  'profile_settings.first_name.label': 'First name',
  'profile_settings.first_name.placeholder': 'First name',
  'profile_settings.first_name.required': 'Please fill in first name',
  'profile_settings.last_name.label': 'Last name',
  'profile_settings.last_name.placeholder': 'Last name',
  'profile_settings.last_name.required': 'Please fill in last name',
  'profile_settings.user_name.label': 'User name',
  'profile_settings.user_name.placeholder': 'User name',
  'profile_settings.user_name.required': 'Please fill in user name',
  'profile_settings.bio.label': 'Bio',
  'profile_settings.bio.placeholder': 'Tell us a little bit about yourself...',
  'profile_settings.save': 'Save changes',
  'profile_settings.profile_picture.file_too_large': 'Please upload smaller picture',
  'profile_settings.profile_picture.upload_failed': 'Profile picture upload failed',
  'profile_settings.profile_picture.edit': 'Edit picture',
  'profile_settings.profile_update_failed': 'Profile update failed',

  'account_settings.label': 'Settings',
  'account_settings.page_title': 'Account settings',
  'account_settings.email.heading': 'Contact details',
  'account_settings.email.verified': 'Your email address is verified.',
  'account_settings.errors.generic': 'Something went wrong, please try again.',
  'account_settings.email.label': 'Email address',
  'account_settings.email.required': 'Please enter email',
  'account_settings.email.invalid': 'Entered email address is invalid',
  'account_settings.email.taken': 'Email address already in use',
  'account_settings.confirm_password.label': 'Password',
  'account_settings.confirm_password.placeholder': 'Enter new password',
  'account_settings.confirm_password.required': 'Please enter password',
  'account_settings.confirm_password.too_short': 'Password should be at least {minLength} characters',
  'account_settings.confirm_email_change_label': 'To verify email address change, please enter your current password',
  'account_settings.confirm_password.failed': 'Incorrect password',

  'account_settings.password.heading': 'Security',

  'account_settings.save_changes': 'Save changes',

  'password_recovery_page.forgot_password.title': 'Forgot your password?',
  'password_recovery_page.forgot_password.message':
    "Enter your email address that you used to register. We'll send you an email with a link to reset your password.",
  'password_recovery_page.forgot_password.email.label': 'Email',
  'password_recovery_page.forgot_password.email.placeholder': 'Email',
  'password_recovery_page.forgot_password.email.required': 'Please enter email',
  'password_recovery_page.forgot_password.email.not_found': 'Email not found',
  'password_recovery_page.forgot_password.email.invalid': 'Email invalid',
  'password_recovery_page.forgot_password.submit': 'Send',

  'PasswordRecoveryForm.emailInvalid': 'A valid email address is required',
  'PasswordRecoveryForm.emailLabel': 'Email',
  'PasswordRecoveryForm.emailNotFound':
    "Hmm. We didn't find an account with that email address. Please double-check the address and try again.",
  'PasswordRecoveryForm.emailPlaceholder': 'john.doe@example.com',
  'PasswordRecoveryForm.emailRequired': 'This field is required',
  'PasswordRecoveryForm.loginLinkInfo': 'Suddenly remembered your password? {loginLink}',
  'PasswordRecoveryForm.loginLinkText': 'Log in.',
  'PasswordRecoveryForm.sendInstructions': 'Send instructions',
  'PasswordRecoveryPage.actionFailedMessage': 'Something went wrong. Please refresh the page and try again.',
  'PasswordRecoveryPage.actionFailedTitle': 'Whoops!',
  'PasswordRecoveryPage.emailSubmittedMessage':
    'The instructions for resetting your password have been sent to {submittedEmailText}.',
  'PasswordRecoveryPage.emailSubmittedTitle': 'Check your inbox',
  'PasswordRecoveryPage.fixEmailInfo': 'Whoops, typo in your email? {fixEmailLink}',
  'PasswordRecoveryPage.fixEmailLinkText': 'Fix it.',
  'PasswordRecoveryPage.forgotPasswordMessage':
    "No worries! Please enter the email address you used when signing up and we'll send you instructions on how to set a new password.",
  'PasswordRecoveryPage.forgotPasswordTitle': 'Forgot your password?',
  'PasswordRecoveryPage.resendEmailInfo': "Didn't get the email? {resendEmailLink}",
  'PasswordRecoveryPage.resendEmailLinkText': 'Send another email.',
  'PasswordRecoveryPage.resendingEmailInfo': 'Resending instructions…',
  'PasswordRecoveryPage.title': 'Request a new password',

  'favorite_listings_page.label': 'Favorite listings',
  'favorite_listings_page.search_button.bike': 'Search bikes',
  'favorite_listings_page.search_button.parts': 'Search parts',
  'favorite_listings_page.search_button.winter': 'Search winter gear',
  'favorite_listings_page.empty_state.bike.title': 'Your saved bikes',
  'favorite_listings_page.empty_state.bike.description': 'Once you save the bike, it will appear here',
  'favorite_listings_page.empty_state.parts.title': 'Your saved bike parts',
  'favorite_listings_page.empty_state.parts.description': 'Once you save the bike part, it will appear here',
  'favorite_listings_page.empty_state.winter.title': 'Your saved winter gear',
  'favorite_listings_page.empty_state.winter.description': 'Once you save the winter gear, it will appear here',
  'favorite_listings_page.schema_title': 'Favorite listings',
  'favorite_listings_page.tabs.bikes': 'Bikes',
  'favorite_listings_page.tabs.parts': 'Parts',
  'favorite_listings_page.tabs.winter': 'Winter',

  'sign_up.confirm_signup_with_idp_title': 'Sign up with {idp}',
  'sign_up.confirm_signup_info_text': 'Please check that your information is correct.',

  'cookies.title': 'We use cookies',
  'cookies.consent_message':
    'Gearro uses cookies to improve your browsing experience, personalize content and ads, and analyze site traffic. By clicking "Accept", you consent to the use of cookies. If you prefer not to allow cookies, you can disable them in your browser settings.',
  'cookies.accept': 'Accept',

  'pagination.previous': 'Previous page',
  'pagination.next': 'Next page',
  'pagination.to_page': 'Go to {page} page',

  'validators.min_max_value': 'Value must be between {minValue} and {maxValue}',
  'validators.min_max_value.invalid_type.numeric': 'Value should be numeric',

  'about_page.schema_title': 'About us | {siteTitle}',
  'about_page.schema_description': 'About Gearro',
  'listing_page.schema_title': '{title} | {siteTitle}',
  'profile_page.schema_title': '{name} | {siteTitle}',
  'search_page.schema_for_search': 'a query',
  'page.schema_title': 'Sell and buy bikes | {siteTitle}',
  'page.schema_description': 'The largest online community for bikes.',
  'edit_listing_page.schema_title': 'Create a listing',
  'log_in.page_schema': 'Log in | Gearro',
  'sign_up.page_schema': 'Sign up | Gearro',
  'password_recovery_page.title': 'Request a new password',
  'inbox_page.schema_title': 'Inbox',
  'new_listing_page.schema_title': 'New listing',

  'search_page.schema_title': 'Buy used bikes | Gearro',
  'search_page.schema_description': 'Buy & sell pre-owned bicycles on Gearro.',
  'search_page.schema_title.bike': 'Discover Your Next Ride: Quality Used Bikes | Gearro',
  'search_page.schema_description.bike':
    'Explore a wide range of quality used bicycles at Gearro. Find your ideal road, mountain, or gravel bike with secure transactions and buyer protection. Start your cycling adventure today.',
  'search_page.schema_title.bike.road': 'High-Performance Road Bikes | Gearro',
  'search_page.schema_description.bike.road':
    'Discover top-tier road bikes for cyclists who demand speed and efficiency. Browse our collection for lightweight, aerodynamic designs at Gearro.',
  'search_page.schema_title.bike.mountain': 'Mountain Bikes | MTB | Gearro',
  'search_page.schema_description.bike.mountain':
    'Tackle the toughest trails with our robust mountain bikes. Engineered for durability and control, our selection at Gearro meets all your off-road cycling needs.',
  'search_page.schema_title.bike.gravel': 'Gravel Bikes | Gearro',
  'search_page.schema_description.bike.gravel':
    'Venture beyond the paved roads with our versatile gravel bikes, designed for comfort and resilience on mixed terrain. Explore more with Gearro.',
  'search_page.schema_title.bike.city': 'Buy or sell pre-loved city bikes',
  'search_page.schema_description.bike.city':
    'Explore our premium selection of city bikes perfect for urban cycling enthusiasts. Find the ideal blend of comfort and style for your daily commute at Gearro.',
  'search_page.schema_title.bike.electric': 'Electric Bikes | E-Bikes | Gearro',
  'search_page.schema_description.bike.electric':
    'Boost your ride with our electric bikes, offering power-assisted cycling for ease and speed. Discover the perfect e-bike to suit your lifestyle at Gearro.',
  'search_page.schema_title.bike.bmx_dirt': 'BMX & Dirt Bikes | Gearro',
  'search_page.schema_description.bike.bmx_dirt':
    'Jump into action with our BMX and dirt bikes, designed for freestyle and off-road challenges at Gearro.',
  'search_page.schema_title.bike.folding': 'Compact Folding Bikes | Portable Bikes | Gearro',
  'search_page.schema_description.bike.folding':
    'Maximize convenience with our folding bikes, combining portability and performance. Ideal for commuting and storage in tight spaces, find yours at Gearro.',
  'search_page.schema_title.bike.other': 'Unique & Specialty Bikes | Find Your Perfect Ride | Gearro',
  'search_page.schema_description.bike.other':
    'Looking for something different? Find unique and specialty bikes for all preferences. Dive into our eclectic collection at Gearro.',

  'search_page.schema_title.parts': 'Bike Parts & Components | Gearro',
  'search_page.schema_description.parts':
    'Shop high-quality bike parts and components. Find frames, suspension, drivetrains, brakes, and more to upgrade your ride at Gearro',
  'search_page.schema_title.parts.frame': 'Bike Frames for Sale | Gearro',
  'search_page.schema_description.parts.frame':
    "Discover durable and lightweight bike frames. Choose the perfect foundation for your custom build with Gearro's selection.",
  'search_page.schema_title.parts.suspension': 'Bike Suspension Systems | Gearro',
  'search_page.schema_description.parts.suspension':
    'Enhance your ride with top-rated bike suspension systems. Find forks and shocks for smooth performance at Gearro.',
  'search_page.schema_title.parts.drivetrain': 'Bike Drivetrains | Gearro',
  'search_page.schema_description.parts.drivetrain':
    'Upgrade your bike with reliable drivetrains. Shop chains, derailleurs, and cranks for efficient power transfer at Gearro.',
  'search_page.schema_title.parts.brakes': 'Bike Brakes | Gearro',
  'search_page.schema_description.parts.brakes':
    'Ensure your safety with high-performance bike brakes. From disc to rim brakes, find all you need at Gearro.',
  'search_page.schema_title.parts.wheels': 'Bike Wheels & Rims | Gearro',
  'search_page.schema_description.parts.wheels':
    'Roll smoothly with our selection of bike wheels and rims. Discover durable options for all terrains at Gearro.',
  'search_page.schema_title.parts.tyres_tubes': 'Bike Tyres for Every Terrain | Gearro',
  'search_page.schema_description.parts.tyres_tubes':
    'Find the perfect bike tyres for any terrain at Gearro. Choose from road, mountain, and gravel options.',
  'search_page.schema_title.parts.cockpit': 'Bike Handlebars & Cockpit Components | Gearro',
  'search_page.schema_description.parts.cockpit':
    'Customize your control center with bike handlebars and cockpit components. Shop quality parts for comfort and performance at Gearro.',
  'search_page.schema_title.parts.seat': 'Bike Seats & Saddles | Gearro',
  'search_page.schema_description.parts.seat':
    'Experience comfort with our range of bike seats and saddles. Find the right fit for long rides at Gearro.',

  'verify_email_page.title': 'Verify your email',
  'verify_email_page.verify_email_address': 'Verify your email',
  'verify_email_page.finish_account_setup':
    'To confirm your email address, please tap the button in the email we sent to {email}',
  'verify_email_page.loading_user_information': 'Loading user information',
  'verify_email_page.email_not_received': "Haven't received the email?",
  'verify_email_page.resend_email': 'Re-send',
  'verify_email_page.verification_error': 'Something went wrong... Please try again later.',

  'verify_phone_page.title': 'Verify your phone',
  'verify_phone_page.verify_phone': 'Verify your phone',
  'verify_phone_page.phone_number': 'Phone number',
  'verify_phone_page.phone_number_placeholder': '+48',
  'verify_phone_page.phone_number_required': 'Please enter phone number',
  'verify_phone_page.phone_number_invalid': 'Invalid phone number',
  'verify_phone_page.incorrect_number_format':
    'The phone number is not in the correct format, have you included the country code?',
  'verify_phone_page.code': 'Code',
  'verify_phone_page.code_placeholder': '4-digit code from SMS',
  'verify_phone_page.code_required': 'Please enter the code',
  'verify_phone_page.code_invalid': 'Invalid code',
  'verify_phone_page.phone_number_info': 'Enter your phone number. This will increase the security of your account.',
  'verify_phone_page.code_info_line_1': 'We sent a text message to {phone}.',
  'verify_phone_page.code_info_line_2': 'Enter the 4-digit code below.',
  'verify_phone_page.loading_user_information': 'Loading user information',
  'verify_phone_page.resend_code': 'Didn’t get it? Resend code',
  'verify_phone_page.generic_error': 'Something went wrong... Please try again later.',
  'verify_phone_page.already_created_error': 'Phone number already verified',

  'PrivacyPolicyPage.schemaTitle': 'Privacy Policy | {siteTitle}',

  'PasswordResetPage.passwordChangedHeading': 'Password changed',
  'PasswordResetPage.passwordChangedHelpText': 'You can now log in with your new password.',
  'PasswordResetPage.loginButtonText': 'Log in',
  'PasswordResetPage.mainHeading': 'Reset your password',
  'PasswordResetPage.helpText': 'Enter your new password below.',
  'PasswordResetForm.passwordLabel': 'New password',
  'PasswordResetForm.passwordPlaceholder': 'Enter new password',
  'PasswordResetForm.passwordRequired': 'Please enter a new password',
  'PasswordResetForm.passwordTooShort': 'Password should be at least {minLength} characters',
  'PasswordResetForm.passwordTooLong': 'Password should be at most {maxLength} characters',
  'PasswordResetForm.submitButtonText': 'Reset password',
  'NotFoundPage.heading': 'Page not found',
  'NotFoundPage.description': "The page you are looking for doesn't exist or has been moved.",

  'accept_terms_of_service_page.title': 'Terms of Service',
  'accept_terms_of_service_page.accept_terms_of_service': 'Accept terms of service',
  'accept_terms_of_service_page.accept_button': 'Accept',
  'accept_terms_of_service_page.generic_error': 'Something went wrong... Please try again later.',

  'wallet.verify-your-identity': 'Verify your identity',
  'wallet.identity-description':
    'This helps us verify your identity and ensures that you are who you say you are. Identity verification is one of the ways we maintain security on Gearro',
  'wallet.add-an-id': 'Add an ID',
  'wallet.choose-an-id-type': 'Choose an ID type to add',
  'wallet.driving-license': 'Driving license',
  'wallet.passport': 'Passport',
  'wallet.identity-card': 'Identity card',
  'wallet.front-of-the-document': 'Front of the Document',
  'wallet.upload-document-front': 'Upload the front side of your document.',
  'wallet.passport-page': 'Passport page',
  'wallet.passport-description':
    'Upload the full page with your picture, making sure to include the two lines of machine-readable code at the bottom.',
  'wallet.upload-from-device': 'Upload from device',
  'wallet.check-quality': 'Check quality',
  'wallet.check-information': 'Make sure the information is seen clearly, with no blur or glare.',
  'wallet.next': 'Next',
  'wallet.retake': 'Retake',
  'wallet.flip-the-document': 'Flip the Document',
  'wallet.flip-to-backside': 'Flip the document and upload the back side.',
  'wallet.error': 'Error',
  'wallet.error-description': 'There was an error verifying your identity. Please try again later.',
  'wallet.try-again': 'Try Again',
  'wallet.thank-you': 'Thank You for Your Submission',
  'wallet.thank-you-for-id':
    'Thank you for submitting your ID. We will let you know if we need anything else from you soon.',
  'wallet.resume': 'Meanwhile, you can resume where you left off.',
  'wallet.done': 'Done',
  'wallet.menu-title': 'Wallet',
  'wallet.identity-verification-in-progress': 'Identity verification in progress...',
  'wallet-identity-verifaction-failed': 'Identity verification failed; please check your details and try again',
  'wallet.identity-verified': 'Your identity has been verified. You can proceed with your transactions.',
  'wallet.available-balance': 'Available Balance',
  'wallet.withdraw': 'Withdraw to bank account',
  'wallet.pending': 'Pending',
  'wallet.no-pending-balances': 'No pending balances',
  'wallet.country-of-bank': 'Country of bank',
  'wallet.full-name-required': 'Full name is required',
  'wallet.full-name': 'Full name',
  'wallet.name-and-surname': 'Name and surname',
  'wallet.account-number-invalid': 'Bank account number is invalid',
  'wallet.bank-account-number': 'Bank account number',
  'wallet.continue': 'Continue',
  'wallet.identity-verification': 'Identity verification',

  'business.title': 'Verify your business',

  'business.business-details': 'Business details',
  'business.company-location': 'Company location',
  'business.owner-information': 'Owner Information',

  'business.business-details.description': 'Please enter your essential business identification information.',
  'business.company-location.description': 'Please provide the official address of your company.',
  'business.owner-information.description': 'Enter the personal details of the business owner.',

  'business.fields.business_name': 'Business name',
  'business.fields.business_name_placeholder': 'Business name',
  'business.fields.business_name_required': 'Business name is required',

  'business.fields.nip': 'NIP',
  'business.fields.nip_placeholder': 'NIP',
  'business.fields.nip_required': 'NIP is required',

  'business.fields.region': 'Region',
  'business.fields.region_placeholder': 'Region',
  'business.fields.region_required': 'Region is required',

  'business.fields.company_address_line_1': 'Company address line 1',
  'business.fields.company_address_line_1_placeholder': 'Company address line 1',
  'business.fields.company_address_line_1_required': 'Company address line 1 is required',

  'business.fields.company_address_line_2': 'Company address line 2',
  'business.fields.company_address_line_2_placeholder': 'Company address line 2',

  'business.fields.postal_code': 'Postal code',
  'business.fields.postal_code_placeholder': 'Postal code',
  'business.fields.postal_code_required': 'Postal code is required',

  'business.fields.city': 'City',
  'business.fields.city_placeholder': 'City',
  'business.fields.city_required': 'City is required',

  'business.fields.first_name': 'First name',
  'business.fields.first_name_placeholder': 'First name',
  'business.fields.first_name_required': 'First name is required',

  'business.fields.last_name': 'Last name',
  'business.fields.last_name_placeholder': 'Last name',
  'business.fields.last_name_required': 'Last name is required',

  'business.fields.date_of_birth': 'Date of birth',
  'business.fields.date_of_birth_placeholder': 'Date of birth',
  'business.fields.date_of_birth_required': 'Date of birth is required',

  'listing_import_page.price_enter': 'Enter the price',
  'listing_import_page.price_description':
    'To finalise your listing, indicate the price at which you want to sell your item.',
  'listing_import_page.link_to_listing': 'Link to your listing',
  'listing_import_page.link_to_listing_description':
    "Enter the full URL of your listing and click import to upload the product to Gearro. You can edit the listing once we've created it?",
  'listing_import_page.import_from_url': 'Import from URL',
  'listing_import_page.import_from_url_required': 'Please enter the URL',
  'listing_import_page.confirm_import_listing':
    'By importing your product, you confirm that you own the listing linked above and authorize Gearro to proceed with the import.',
  'listing_import_page.continue': 'Continue',
  'listing_import_page.price': 'Price',
  'listing_import_page.listing_is_being_imported': 'Your listing is being imported',
  'listing_import_page.see_my_ad': 'See my ads',
  'listing_import_page.import_in_progress': 'Import in progress',
  'listing_import_page.import_in_progress_description': 'Approve order in order to start shipping process',
  'listing_import_page.listing_verification': 'Listing verification',
  'listing_import_page.listing_verification_description': 'Listing verification by our team may take up to 48 hours',
  'listing_import_page.listing_is_active': 'The ad is active',
  'listing_import_page.listing_is_active_description': 'You can make edits as needed',
  'listing_import_page.whats_next': 'What’s next?',

  'listing_type_select.badge_new': 'New',
  'listing_type_select.import_from_link_description': 'Posted item on another platform? Paste the link to import it.',
  'listing_type_select.start_from_scratch_description': 'Enter your item details manually.',
  'listing_type_select.import_from_link_title': 'Import from a link',
  'listing_type_select.start_from_scratch_title': 'Start from scratch',
  // 10 seconds
  'listing_type_select.time_content_import_from_link': '10 seconds',
  // 1 minute
  'listing_type_select.time_content_start_from_scratch': '1 minute',
  'listing_type_select.continue': 'Continue',
  'listing_type_select.how_would_you_like_to_post_your_listing': 'How would you like to post your listing?',
  'listing_type_select.imported_by_url': 'Imported by URL',
  'listing_type_select.in_progress': 'In Progress',
  'listing_type_select.rejected': 'Rejected',
  'listing_type_select.active': 'Active',

  'faq.frequently_asked_questions': 'Frequently Asked Questions',
  'faq.faq': 'FAQ',

  'faq.account_and_general_questions': 'Gearro Account & General Questions',

  'faq.how_do_i_create_an_account_on_gearro': 'How do I create an account on Gearro?',
  'faq.how_do_i_reset_my_password': 'How do I reset my password?',
  'faq.how_do_i_delete_my_gearro_account': 'How do I delete my Gearro account?',
  'faq.is_it_safe_to_buy_and_sell_on_gearro': 'Is it safe to buy and sell on Gearro?',
  'faq.how_do_i_report_a_suspicious_listing_or_user': 'How do I report a suspicious listing or user?',
  'faq.how_can_i_contact_gearro_customer_support': 'How can I contact Gearro customer support?',

  'faq.how_do_i_create_an_account_on_gearro_answer':
    'To create an account, click on the "Sign Up" button at the top of the topbar. Register using your email address or sign in with your "Google" account, and follow the prompts to complete your registration.',
  'faq.how_do_i_reset_my_password_answer':
    'If you\'ve forgotten your password, select "Forgot your password" during the login process. You\'ll receive an email with a link to reset your password. Contact us for any issues during the reset process.',
  'faq.how_do_i_delete_my_gearro_account_answer':
    "If you're considering leaving Gearro, we'd value your feedback. To delete your account in compliance with GDPR, please email us at info@gearro.com.",
  'faq.is_it_safe_to_buy_and_sell_on_gearro_answer':
    'Yes, as long as you stay on the platform. We strive to provide a safe marketplace and recommend conducting transactions through our platform to minimize risks.',
  'faq.how_do_i_report_a_suspicious_listing_or_user_answer':
    'Contact our support team if you encounter a suspicious listing or user. We will review the report and take appropriate action.',
  'faq.how_can_i_contact_gearro_customer_support_answer':
    'Contact our customer support by sending your inquiry to info@gearro.com. Describe your case, and our team will assist you promptly.',

  'faq.selling_on_gearro': 'Selling on Gearro',

  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro': 'How do I sell an item on Gearro?',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro': 'Is it free to list an item on Gearro?',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly':
    'How can I increase the chances of selling my item quickly?',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro':
    'How long does a listing stay active on Gearro?',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing': 'How can I delete or edit my listing?',

  'faq.selling_on_gearro.how_do_i_sell_an_item_on_gearro_answer':
    'Selling is easy: create an account, click the "Sell" button, fill in the item details, upload photos, and your listing will go live. Buyers can contact you directly or make a purchase.',
  'faq.selling_on_gearro.is_it_free_to_list_an_item_on_gearro_answer':
    'Yes, you can upload and sell as many items as you like for free.',
  'faq.selling_on_gearro.how_can_i_increase_the_chances_of_selling_my_item_quickly_answer':
    'Provide a detailed and honest description, include high-quality photos, and set a reasonable price.',
  'faq.selling_on_gearro.how_long_does_a_listing_stay_active_on_gearro_answer':
    'Listings remain active until the item is sold or the listing is deleted.',
  'faq.selling_on_gearro.how_can_i_delete_or_edit_my_listing_answer':
    'To delete or edit a listing, log into your account, navigate to "My Listings," and select the appropriate option for the listing you wish to modify.',

  'faq.buying_on_gearro': 'Buying on Gearro',
  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro': 'How do I buy items on Gearro?',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction':
    'What should I do if I have a problem with a transaction?',
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase': 'Can I return an item after purchase?',

  'faq.buying_on_gearro_how_do_i_buy_items_on_gearro_answer':
    'Browse listings using the search and filter options, click on the listing for details, and purchase by clicking the "BUY NOW" button. You can also contact the seller directly if you have questions.',
  'faq.buying_on_gearro_what_should_i_do_if_i_have_a_problem_with_a_transaction_answer':
    "Try resolving it directly with the other party first; if unsuccessful, contact Gearro's customer support with the transaction details.",
  'faq.buying_on_gearro_can_i_return_an_item_after_purchase_answer':
    'Returns and refunds are possible within 48 hours after purchase if you claim an issue. Each situation will be resolved by our customer support team.',

  'faq.shipping': 'Shipping',

  'faq.shipping.how_does_dpd_shipping_work_on_gearro': 'How does DPD shipping work on Gearro?',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd': 'How do I prepare my bike for shipping with DPD?',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping':
    'Can I schedule a specific pickup time for shipping?',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping':
    'Are there any additional fees for using DPD shipping?',

  'faq.shipping.how_does_dpd_shipping_work_on_gearro_answer':
    'Upon purchase, the seller receives a prepaid shipping label from DPD and arranges for pickup. You will receive tracking information to monitor the delivery status.',
  'faq.shipping.how_do_i_prepare_my_bike_for_shipping_with_dpd_answer':
    'Pack your bike securely in a bike box or suitable container, remove the pedals and front wheel, and use padding to protect it during transit.',
  'faq.shipping.can_i_schedule_a_specific_pickup_time_for_shipping_answer':
    'Yes, after purchasing a shipping label, you can select a convenient pickup time for DPD to collect your package.',
  'faq.shipping.are_there_any_additional_fees_for_using_dpd_shipping_answer':
    'There are no additional fees charged to sellers for using DPD shipping. Shipping costs for buyers are calculated and displayed at checkout.',

  'faq.contact_us': 'Contact us',
  'faq.contact_us_description': "Feel free to email us at {email}. We'll get back to you within 24 hours.",
  'packaging_instructions.schema_title': 'Pack your order | {siteTitle}',
  'packaging_instructions.page_title': 'Packing your item',
  'packaging_instructions.header_1': 'Pack your order',
  'packaging_instructions.description_1':
    'To create an account, click on the "Sign Up" button at the top of the topbar. Register using your email address or sign in with your “Google” account, and follow the prompts to complete your registration.',
  'packaging_instructions.header_2': 'Find the box',
  'packaging_instructions.description_2':
    'Before you start packing, secure the right-sized shipping box. Bike shops are a great resource for this, often providing boxes suitable for bicycles either for free or a minimal fee.',
  'packaging_instructions.header_3': 'Bike protection',
  'packaging_instructions.description_3':
    'While general packaging knowledge is beneficial, dispatching a bicycle requires extra attention. Follow these steps for a secure and efficient packing process:',
  'packaging_instructions.packing.title_1': 'Remove pedals',
  'packaging_instructions.packing.description_1':
    'Your right pedal has a regular thread (counter-clockwise to loosen), while your left pedal is reverse threaded (clockwise to loosen).',
  'packaging_instructions.packing.title_2': 'Remove wheels',
  'packaging_instructions.packing.description_2':
    'Remove wheels from your bike. \nIf your bike has disc brakes, place a brake pad spacer in your brake calipers to maintain disc brake separation. Secure in place with a zip tie.',
  'packaging_instructions.packing.title_3': 'Remove bars',
  'packaging_instructions.packing.description_3':
    'Remove handlebars from your stem. \nLeave your stem in place to maintain bearing compression in your head tube. \nScrew the stem bolts back into your stem.',
  'packaging_instructions.packing.title_4': 'Remove seatpost',
  'packaging_instructions.packing.description_4':
    'Remove seatpost, and tighten your seatpost collar. \nRemoving these parts reduces the risk of damage during transit and results in a more compact package.',
  'packaging_instructions.packing.title_5': 'Protect bike',
  'packaging_instructions.packing.description_5':
    'Bubble Wrap: Wrap the frame and detached parts with bubble wrap to guard against scratches and dents. \nSecure Loose Parts: Make sure all detached parts are securely wrapped and immobilized inside the box to prevent damage.',
  'packaging_instructions.packing.title_6': 'Take photos',
  'packaging_instructions.packing.description_6':
    'Document the contents of your shipment and its internal and external packing. \nIn the unlikely event of loss or damage, such photos will help you remember what you packed and serve as proof of proper packing to submit with your claim.',

  'blog_page.schema_title': 'Latest articles | {siteTitle}',
  'blog_page.page_title': 'Latest articles',
  'blog_page.header': 'Latest articles',
  'blog_page.not_found_schema_title': 'Article not found | {siteTitle}',
  'blog_page.not_found_title': 'Article not found',
  'blog_page.not_found_description': 'The article you are looking for does not exist.',
};
